import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar-modal',
  template: `
    <ion-toolbar>
      <ng-template [ngIf]="canClose">
        <ion-buttons slot="start">
          <ion-button (click)="dismissEvent?.emit()" color="dark" *ngIf="type !== 'sheet' && endActionIcon !== undefined">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
          <ion-button (click)="startActionEvent?.emit()" [color]="startActionColor" *ngIf="startActionIcon !== undefined && type === 'sheet'" class="ion-hide-md-up">
            <ion-icon slot="icon-only" [name]="startActionIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="dismissEvent?.emit()" color="dark" *ngIf="type !== 'sheet' && endActionIcon === undefined">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
          <ion-button (click)="endActionEvent?.emit()" [color]="endActionColor" [disabled]="endActionDisabled" *ngIf="endActionIcon !== undefined && type === 'sheet'" class="ion-hide-md-up">
            <ion-icon slot="icon-only" [name]="endActionIcon"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ng-template>
      <ion-title *ngIf="title">{{title}}</ion-title>
      <ng-content></ng-content>
    </ion-toolbar>
  `,
  styleUrls: ['./toolbar-modal.component.scss']
})
export class ToolbarModalComponent {

  // Modal is an ionic modal which displayed in full screen
  // Popup is an ionic modal which displayed as popup
  // Sheet is an ionic modal which displayed as sheet document
  @Input() type: 'modal' | 'popup' | 'sheet' = 'modal';

  @Input() title?: string;

  @Input() canClose: boolean = true;

  @Input() startActionIcon?: string;
  @Input() startActionColor: string = 'primary';

  @Input() endActionIcon?: string;
  @Input() endActionColor: string = 'primary';
  @Input() endActionDisabled: boolean = false;

  @Output() startActionEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() endActionEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output() dismissEvent: EventEmitter<void> = new EventEmitter<void>();

}
