import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfileInterface } from '@common/interfaces/common.interface';
import { Subscription } from 'rxjs';
import { AccessControlService, PermissionsForDashboardInterface } from '@common/services/access-control';
import { BadgeNotificationsEventsInterface, NotificationsService } from '@common/services/notifications';
import { ConnectionService } from '@common/services/connection.service';
import { MenuController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { OffersController } from '@common/components/modals/offers';
import { ToolboxService } from '@common/services/toolbox.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { params } from '@environments/params';
import { Browser } from '@maslo/browser';


interface GroupItem {
  icon: string;
  titleKey: string;
  groupKey: string;
  items?: MenuItem[];
  isActive?: boolean;

  route?: string;
  hasAccess: () => boolean;
  nbEvents: () => number;
}

interface MenuItem {
  icon: string;
  titleKey?: string;
  title?: string;
  route: string;
  routeMask?: string;
  key: string;
  isActive?: boolean;
  hasAccess: () => boolean;
  hasEvent: () => boolean;
}


@Component({
  selector: 'app-dashboard-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class DashboardSideMenuComponent implements OnInit, OnDestroy {

  profile?: UserProfileInterface;
  permissions?: PermissionsForDashboardInterface;
  badgeEvents?: BadgeNotificationsEventsInterface;

  nbPlatforms: number = 10;
  idPlatform: number = 0;
  notificationsAdminCount: number = 0;

  isSettingsActive: boolean = false;
  canCreateItemsInDashboard: boolean = false;

  groups: GroupItem[] = [];
  expandedGroup?: string;

  adminHelpUrl: string = params.links.admin_help_link;

  private subscriptions: Subscription[] = [];

  constructor(protected connectionServ: ConnectionService,
              protected accessServ: AccessControlService,
              protected notificationsServ: NotificationsService,
              protected menuCtrl: MenuController,
              protected offersCtrl: OffersController,
              protected toolboxServ: ToolboxService,
              protected router: Router) {
  }

  ngOnInit() {
    this.subscriptions.push(this.connectionServ.getProfileState().subscribe(profile => {
      if (profile?.user) {
        this.profile = profile?.user;
        this.buildMenu();
      }
    }));

    this.subscriptions.push(this.accessServ.permissionsForDashboardState().subscribe(permissions => {
      this.permissions = permissions;

      if (this.idPlatform !== permissions.platform.id_action) {
        this.idPlatform = permissions.platform.id_action;
        this.buildMenu();
        this.buildPermissions();
      }
    }));

    this.subscriptions.push(this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      distinctUntilChanged()
    ).subscribe(() => {
      this.buildActivationItem();
    }));

    this.subscriptions.push(this.notificationsServ.eventsState(true).subscribe(events => this.badgeEvents = events));
    this.subscriptions.push(this.notificationsServ.getInAppNotificationsAdminCountState().subscribe(val => this.notificationsAdminCount = val));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Build permissions
   */
  buildPermissions() {
    this.canCreateItemsInDashboard = (this.permissions.isChallengesEnabled && !!this.permissions.permissions.Challenge?.add) ||
      (this.permissions.isRoadboxEnabled && !!this.permissions.permissions.Gallery?.add) ||
      (this.permissions.isQuizEnabled && !!this.permissions.permissions.Quizz?.add) ||
      (this.permissions.isSurveysEnabled && !!this.permissions.permissions.Sondage?.add) ||
      (this.permissions.isFormsEnabled && !!this.permissions.permissions.Form?.add);
  }

  /**
   * Build activation item
   */
  buildActivationItem() {

    if (!this.permissions?.platform || !this.profile) {
      return;
    }

    const url = this.router.url;
    this.expandedGroup = undefined;

    this.isSettingsActive = url.indexOf('/dashboard/settings') > -1;

    this.groups.forEach(group => {
      let isActive = false;

      group.isActive = false;

      group.items?.forEach(item => {
        item.isActive = url.indexOf(item.routeMask || item.route) > -1;

        if (item.isActive) {
          isActive = true;
        }
      });
      if (isActive) {
        group.isActive = true;
        this.expandedGroup = group.groupKey;
      }
    });
  }

  /**
   * Build menu
   */
  buildMenu() {
    this.groups = [];

    if (!this.permissions?.platform || !this.profile) {
      return;
    }

    const statsGroup: MenuItem[] = [
      {
        titleKey: 'dashboard.menu.challenges',
        route: '/dashboard/stats/challenges',
        key: 'challenges',
        icon: 'podium',
        hasAccess: () => this.permissions.isStatsEnabled && this.permissions.isChallengesEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.stats.menu.interactions',
        route: '/dashboard/stats/interactions',
        key: 'interactions',
        icon: 'people-circle',
        hasAccess: () => this.permissions.isStatsEnabled && this.permissions.isFeedEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.stats.menu.audience',
        route: '/dashboard/stats/audience',
        key: 'audience',
        icon: 'bar-chart',
        hasAccess: () => this.permissions.isStatsEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.menu.e-store',
        route: '/dashboard/stats/e-store',
        key: 'e-store',
        icon: 'storefront',
        hasAccess: () => this.permissions.isStatsEnabled && this.permissions.clientPermissions?.isStoreEnabled,
        hasEvent: () => false
      }
    ];

    const usersGroup: MenuItem[] = [
      {
        titleKey: 'dashboard.users.users',
        route: '/dashboard/members',
        routeMask: '/dashboard/member',
        key: 'users',
        icon: 'rd-user',
        hasAccess: () => this.permissions.isUsersEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.users.teams',
        route: '/dashboard/teams',
        routeMask: '/dashboard/team',
        key: 'teams',
        icon: 'rd-users',
        hasAccess: () => this.permissions.isTeamsEnabled,
        hasEvent: () => false
      }
    ];

    const rewardsGroup: MenuItem[] = [
      {
        titleKey: 'dashboard.menu.cash',
        route: '/dashboard/rewards/cash',
        icon: 'cash',
        key: 'cash',
        hasAccess: () => this.permissions.isRewardsEnabled && this.permissions.isRewardCashEnabled,
        hasEvent: () => false
      },
      {
        title: this.permissions?.platform?.money_name || 'Coins',
        route: '/dashboard/rewards/coins',
        icon: 'rd-coins',
        key: 'coins',
        hasAccess: () => this.permissions.isRewardsEnabled && this.permissions.isRewardCoinsEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.menu.gifts',
        route: '/dashboard/rewards/gifts',
        icon: 'gift',
        key: 'gifts',
        hasAccess: () => this.permissions.isRewardsEnabled && this.permissions.isRewardGiftsEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.menu.badges',
        route: '/dashboard/rewards/badges',
        icon: 'medal',
        key: 'badges',
        hasAccess: () => this.permissions.isRewardsEnabled && this.permissions.isRewardBadgesEnabled,
        hasEvent: () => false
      }
    ];

    const eStoreGroup: MenuItem[] = [
      {
        titleKey: 'dashboard.e-store.billing.title',
        route: '/dashboard/e-store/billing',
        key: 'billing',
        icon: 'card',
        hasAccess: () => this.permissions.isBillingEnabled && this.permissions.clientPermissions?.isStoreEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.e-store.orders.title',
        route: '/dashboard/e-store/orders',
        key: 'orders',
        icon: 'list-circle',
        hasAccess: () => this.permissions.isBillingEnabled && this.permissions.clientPermissions?.isStoreEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.e-store.categories.title',
        route: '/dashboard/e-store/categories',
        key: 'categories',
        icon: 'rd-categories',
        hasAccess: () => this.permissions.isBillingEnabled && this.permissions.clientPermissions?.isStoreEnabled,
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.e-store.brands.title',
        route: '/dashboard/e-store/brands',
        key: 'brands',
        icon: 'rd-brand',
        hasAccess: () => this.permissions.isBillingEnabled && this.permissions.clientPermissions?.isStoreEnabled,
        hasEvent: () => false
      }
    ];

    const modulesGroup: MenuItem[] = [
      {
        titleKey: 'dashboard.menu.challenges',
        route: '/dashboard/challenges',
        icon: 'podium',
        key: 'challenges',
        hasAccess: () => this.permissions.isChallengesEnabled,
        hasEvent: () => this.badgeEvents?.challengesFlag
      },
      {
        titleKey: 'dashboard.menu.feed',
        route: '/dashboard/feed',
        icon: 'reader',
        key: 'feed',
        hasAccess: () => this.permissions.isFeedEnabled && (
          this.permissions.isMainSettingsEnabled || (
            this.permissions.isTeamsEnabled && !!this.permissions.permissions.Team?.update
          )
        ),
        hasEvent: () => false
      },
      {
        titleKey: 'dashboard.menu.quiz',
        route: '/dashboard/quizzes',
        icon: 'extension-puzzle',
        key: 'quizzes',
        hasAccess: () => this.permissions.isQuizEnabled,
        hasEvent: () => this.badgeEvents?.quizzesFlag
      },
      {
        titleKey: 'dashboard.menu.forms',
        route: '/dashboard/forms',
        icon: 'rd-form',
        key: 'forms',
        hasAccess: () => this.permissions.isFormsEnabled,
        hasEvent: () => this.badgeEvents?.formsFlag
      },
      {
        titleKey: 'dashboard.menu.surveys',
        route: '/dashboard/surveys',
        icon: 'rd-survey',
        key: 'surveys',
        hasAccess: () => this.permissions.isFeedEnabled && this.permissions.isSurveysEnabled,
        hasEvent: () => this.badgeEvents?.surveysFlag
      },
      // --roadbox--
      {
        titleKey: 'dashboard.menu.roadbox',
        route: '/dashboard/library',
        icon: 'library',
        key: 'roadbox',
        hasAccess: () => this.permissions.isRoadboxEnabled,
        hasEvent: () => false
      }
    ];

    this.groups = [
      {
        titleKey: 'dashboard.menu.stats',
        icon: 'stats-chart',
        groupKey: 'stats',
        hasAccess: () => true,
        nbEvents: () => statsGroup.filter(item => item.hasEvent()).length || 0,
        items: statsGroup
      },
      {
        titleKey: 'dashboard.menu.users',
        icon: 'rd-users',
        groupKey: 'users-teams',
        hasAccess: () => true,
        nbEvents: () => usersGroup.filter(item => item.hasEvent()).length || 0,
        items: usersGroup
      },
      {
        titleKey: 'dashboard.menu.rewards',
        icon: 'trophy',
        groupKey: 'rewards',
        hasAccess: () => true,
        nbEvents: () => rewardsGroup.filter(item => item.hasEvent()).length || 0,
        items: rewardsGroup
      },
      {
        titleKey: 'dashboard.menu.e-store',
        route: '/dashboard/e-store',
        icon: 'storefront',
        groupKey: 'e-store',
        hasAccess: () => true,
        nbEvents: () => eStoreGroup.filter(item => item.hasEvent()).length || 0,
        items: eStoreGroup
      },
      {
        titleKey: 'dashboard.menu.modules',
        icon: 'apps',
        groupKey: 'apps',
        hasAccess: () => true,
        nbEvents: () => modulesGroup.filter(item => item.hasEvent()).length || 0,
        items: modulesGroup
      }
    ];

    this.buildActivationItem();
  }

  /**
   * Open offers popup or restricted message warning
   * @param item MenuItem
   */
  openPopup(item: MenuItem) {
    this.closeDashboardMenu();
    if (!item.hasAccess()) {
      if (this.permissions.isAdminUser) {
        this.offersCtrl.open().then(/* Nothing to do */);
      } else if (this.permissions.platform?.access.id_role > 0) {
        this.toolboxServ.displayRoleRestrictedAccessWarning();
      }
    }
  }

  /**
   * Open admin help
   */
  openHelp($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.closeDashboardMenu();
    Browser.open({url: this.adminHelpUrl}).then(/* Nothing to do */);
  }

  /**
   * Close platform menu
   */
  closeDashboardMenu() {
    this.menuCtrl.close('dashboardMenu')?.then(/* Nothing to do */);
  }

}
