// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,   // Production mode for build,
  context: 'dev',
  apiRoot: 'https://devapi.git.roadoo.com/v4',
  // apiRoot: 'https://api.sandbox.roadoo.com/v4',
  // apiRoot: 'https://preprodapi.git.roadoo.com/v4',
  // apiRoot: 'https://api.roadoo.com/v4',
  mediaRoot: 'http://dev.admin.roadoo.com',
  // mediaRoot: 'http://admin.roadoo.com',
  checkoutRoot: 'https://checkout-dev.roadoo.com',
  // intercomAppId: null,
  // talkJs: null,
  intercomAppId: 'l1q0tflz',
  talkJs: {
    appId: 'taoVjCdS',
    secret: 'sk_test_Lz3YlU0DXrlCiVYKpMBhoLuCwzzFBanY',
    // Data linked to backend
    env: 'dev'
  },
  cookieDomain: '.roadoo.com',
  pexelsKey: '563492ad6f91700001000001673db5bf17c441649c6d4bcf04715aa3',
  giphyKey: 'RPl8APS4hblvloccZMffyIwP4yIu9VbZ',
  stripeKey: 'pk_test_8mfb9HKnWvbD4Pbfa4Qk4QtE002HeagK3J',
  slackToken: 'xoxb-604604965858-7751433674710-Unkg1O2VSGS9f680v0WQF37v'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
