import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'locNumber'
})
export class LocNumberPipePipe extends DecimalPipe implements PipeTransform {

  constructor(private translateServ: TranslocoService) {
    super(translateServ.getActiveLang() || 'en');
  }

  public transform(value: any, digitsInfo?: string, locale?: string): any {
    console.log(value, locale || this.translateServ.getActiveLang());
    return super.transform(value, digitsInfo || '3.0-3', locale || this.translateServ.getActiveLang()) || '';
  }

}
