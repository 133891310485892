import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { ResponsiveService } from '@common/services/responsive.service';
import { Subscription } from 'rxjs';
import {
  AccessControlService,
  PermissionsForDashboardInterface
} from '@common/services/access-control';
import { NavController, PopoverController } from '@ionic/angular';
import { NotificationsPopover } from '../notifications/notifications.popover';
import { Router } from '@angular/router';
import { TalkService } from '@common/components/app/talk';
import { NotificationsService } from '@common/services/notifications';

@Component({
  selector: 'app-side-buttons',
  templateUrl: './side-buttons.component.html',
  styleUrls: ['./side-buttons.component.scss']
})
export class AppSideButtonsComponent implements OnInit, OnDestroy {

  @Input() isDashboard: boolean = false;

  notificationsCount: number = 0;
  notificationsAdminCount: number = 0;
  messagesCount: number = 0;

  subscriptions: Subscription[] = [];

  permissions?: PermissionsForDashboardInterface;

  hasDashboardEvents: boolean = false;

  isTalkEnabled: boolean = false;

  canAccessDashboard: boolean = false;

  dashboardMainRoute: string = '/';

  constructor(public commonServ: CommonService,
              public responsiveServ: ResponsiveService,
              protected popoverCtrl: PopoverController,
              protected navCtrl: NavController,
              protected accessServ: AccessControlService,
              protected notificationsServ: NotificationsService,
              protected talkServ: TalkService,
              protected router: Router) {
  }

  ngOnInit() {
    this.subscriptions.push(this.accessServ.permissionsForDashboardState().subscribe(permissions => this.buildPermissions(permissions)));
    this.subscriptions.push(this.notificationsServ.getInAppNotificationsUserCountState().subscribe(val => this.notificationsCount = val));
    this.subscriptions.push(this.notificationsServ.getInAppNotificationsAdminCountState().subscribe(val => this.notificationsAdminCount = val));
    this.subscriptions.push(this.notificationsServ.eventsState(true).subscribe(events => this.hasDashboardEvents = events.accessFlag));
    this.subscriptions.push(this.talkServ.getUnreadMessagesCountState().subscribe(val => this.messagesCount = val));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  buildPermissions(permissions: PermissionsForDashboardInterface) {
    this.permissions = permissions;

    this.canAccessDashboard = this.commonServ.canAccessDashboard();
    this.dashboardMainRoute = this.accessServ.getDashboardMainRoute();

    this.isTalkEnabled = this.accessServ.permissionsForClient().isTalkEnabled;
  }

  /**
   * Open notifications
   * @param event click event
   */
  async openNotifications(event: any) {
    if (this.responsiveServ.isMobile) {
      if (this.isDashboard) {
        this.router.navigate(['/dashboard/notifications'])?.then(/* Nothing to do */);
      } else {
        this.navCtrl.navigateForward('/notifications')?.then(/* Nothing to do */);
      }
    } else {
      const popover = await this.popoverCtrl.create({
        component: NotificationsPopover,
        cssClass: 'notifications-popover',
        event,
        translucent: true,
        showBackdrop: false,
        mode: 'ios'
      });
      return await popover.present();
    }
  }

}
