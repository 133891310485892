import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import {
  AdminStatsInteractionsInterface,
  AdminStatsFilterData,
  ReqAdminStatsAudienceInterface,
  ReqAdminStatsInteractionsInterface,
  ReqAdminStatsPerformancesInterface,
  ReqAdminStatsEStoreInterface
} from '@common/interfaces/api/admin';
import { HttpClient } from '@angular/common/http';
import { AdminUsersService } from './users.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AdminBillingService } from './billing.service';
import { PaginatorInterface } from '@common/interfaces/common.interface';

@Injectable()
export class AdminStatsService extends HttpBaseService {

  constructor(protected http: HttpClient,
              protected usersServ: AdminUsersService,
              protected billingServ: AdminBillingService) {
    super(http);
  }

  /**
   * Get interactions from backend
   * @param filter filter data
   */
  interactions(filter?: AdminStatsFilterData): Observable<AdminStatsInteractionsInterface> {
    const body = new FormData();
    if (filter?.date_from) {
      body.append('date_from', filter.date_from);
    }
    if (filter?.date_to) {
      body.append('date_to', filter.date_to);
    }

    return this.stdRequest<ReqAdminStatsInteractionsInterface>(
      this.http.post<ReqAdminStatsInteractionsInterface>(`${ this.rootApi }/administration/Stats/Interactions`, body)
    ).pipe(map(response => response.data));
  }

  /**
   * Get audience from backend
   * @param filter filter data
   */
  audience(filter?: AdminStatsFilterData): Observable<ReqAdminStatsAudienceInterface> {
    const body = new FormData();
    if (filter?.date_from) {
      body.append('date_from', filter.date_from);
    }
    if (filter?.date_to) {
      body.append('date_to', filter.date_to);
    }

    return this.stdRequest<ReqAdminStatsAudienceInterface>(
      this.http.post<ReqAdminStatsAudienceInterface>(`${ this.rootApi }/administration/Stats/Audience`, body)
    );
  }

  /**
   * Get estore stats from backend
   * @param filter filter data
   */
  eStore(filter?: AdminStatsFilterData): Observable<ReqAdminStatsEStoreInterface> {
    return this.stdRequest<ReqAdminStatsEStoreInterface>(
      this.http.post<ReqAdminStatsEStoreInterface>(`${ this.rootApi }/administration/Stats/Estore`, null)
    );
  }

  /**
   * Used to get statistics about users performances on challenges.
   * @param idChallenge challenge to get
   * @param filter filter object with date_from, date_to and get_csv
   * @param paginator paginator object
   */
  performances(idChallenge?: string,
               filter?: AdminStatsFilterData,
               paginator?: PaginatorInterface): Observable<ReqAdminStatsPerformancesInterface> {
    const body = new FormData();

    if (filter?.date_from) {
      body.append('date_from', filter.date_from);
    }
    if (filter?.date_to) {
      body.append('date_to', filter.date_to);
    }

    if (idChallenge) {
      body.append('id_challenge', idChallenge);

      if (filter?.csv_fields && filter.csv_fields.length > 0) {
        body.append('csv_fields', JSON.stringify(filter.csv_fields));
      }
    } else {
      body.append('order_by', filter?.order_by || 'date_debut');
      body.append('order_way', filter?.order_way || 'DESC');

      if (filter?.users && filter.users.length > 0) {
        body.append('users', JSON.stringify(filter.users.map(val => val.toString())));
      }
      if (filter?.extras && filter.extras.length > 0) {
        body.append('extras', JSON.stringify(filter.extras));
      }
      if (filter?.successLevels && filter.successLevels.length > 0) {
        body.append('success_level', JSON.stringify(filter.successLevels));
      }

      let page = paginator?.page || 1;
      let max = paginator?.max || 20;
      page = page < 1 ? 1 : page;
      max = max < 1 ? 1 : max;
      const start = (page - 1) * max;
      body.append('debut', start.toString());
      body.append('limit', max.toString());
    }

    if (filter?.get_csv) {
      body.append('get_csv', '1');
    }

    return this.stdRequest<ReqAdminStatsPerformancesInterface>(this.http.post<ReqAdminStatsPerformancesInterface>(
      `${ this.rootApi }/administration/Stats/Performances`,
      body
    )).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }

}
