import { BehaviorSubject, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { HttpBaseService } from './api/http-base.service';
import { AuthCodeInterface, TokenInterface } from '@common/interfaces/connection.interface';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import {
  InitInterface,
  PlatformInterface,
  ReqValidateTokenInterface,
  StandardResponseInterface
} from '@common/interfaces/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '@common/helpers/utils';
import { UserProfileInterface } from '@common/interfaces/common.interface';
import { toUserProfile, toUserOptions } from '@common/helpers/users';
import { AppInfo } from '@capacitor/app/dist/esm/definitions';
import { UserOptionsInterface } from '@common/interfaces/api/client';

/**
 * Used to store a promise and resolve it later
 */
class InitDeferred<T> {
  public promise: Promise<T>;
  public resolve: (result: T | PromiseLike<T>) => void;
  public reject: (reason: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

interface UserDataInterface {
  user: UserProfileInterface;
  options: UserOptionsInterface;
}

/**
 * Connection service to manage authentication and connection.
 * User is considered authenticated if token is set.
 * User is considered connected if token and platform are set
 */
@Injectable({
  providedIn: 'root'
})
export class ConnectionService extends HttpBaseService {

  private initInProgress = false;
  private initValue: InitInterface;
  private appId: string;
  private currentVersion: string;
  private profileInProgress = false;
  private lastPlatform: PlatformInterface;
  private isMaintenanceValue: boolean = false;

  private connectionData = {
    email: null,
    password: null,
  };

  /**
   * Subject to manage authentication state.
   * State changed when authentication state is updated (connected / Unconnected)
   */
  private authState: BehaviorSubject<TokenInterface> = new BehaviorSubject(null);

  /**
   * Subject to manage platform state
   * @private
   */
  private platformState: BehaviorSubject<PlatformInterface> = new BehaviorSubject(null);

  /**
   * Subject to manage user profile and user options
   * @private
   */
  private profileState: BehaviorSubject<UserDataInterface> = new BehaviorSubject(undefined);

  /**
   * Subject to manage connection
   * @private
   */
  private connectionState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Indicate when connection is ready
   *  - false: connection is ready
   *  - true: connection is ready with error
   * @private
   */
  private initDeferred: InitDeferred<boolean> = new InitDeferred<boolean>();

  constructor(protected http: HttpClient,
              private storage: Storage,
              private translate: TranslocoService) {
    super(http);
  }


  /**
   * Check if value is valid
   * @param value
   * @private
   */
  static isValid(value: any) {
    return value !== null && value !== undefined;
  }

  /**
   * Initialize Auth Service
   * Shall be called in main app
   */
  public init() {

    // Check if user has token in order to load local data
    this.getToken().subscribe((res) => {
      if (res) {
        // Check session and refresh user token
        this.verifySession().subscribe({
          next: response => {
            if (response?.user) {
              this.updateTokenProfile(response.user);
            }
            // Refresh local platforms
            this.initPlatform(this.getTokenValue()).subscribe(() => {
              this.initDeferred.resolve(false);
            });
          },
          error: err => {
            console.error(err);
            this.initDeferred.resolve(true);
            this.logout();
          }
        });
      } else {
        this.initDeferred.resolve(false);
      }
    });

    // Manage events of token, platform and profile
    this.authState.subscribe(token => {
      this.checkConnectionState(token, this.platformState.getValue(), this.profileState.getValue()?.user);
    });

    this.platformState.subscribe(platform => {
      this.checkConnectionState(this.authState.getValue(), platform, this.profileState.getValue()?.user);
    });

    this.profileState.subscribe(profile => {
      this.checkConnectionState(this.authState.getValue(), this.platformState.getValue(), profile?.user);
    });
  }

  public isReady(): Promise<boolean> {
    return this.initDeferred.promise;
  }

  public isMaintenance(): boolean {
    return this.isMaintenanceValue;
  }

  public setIsMaintenance(value: boolean) {
    this.isMaintenanceValue = value;
  }

  /**
   * Return the auth state to manage connection change event
   */
  public getAuthState(): Observable<TokenInterface> {
    return this.authState.asObservable();
  }

  /**
   * Return the connection state value
   */
  public getConnectionState(): Observable<boolean> {
    return this.connectionState.asObservable();
  }

  /**
   * Return the platform state
   */
  public getPlatformState() {
    return this.platformState.asObservable();
  }

  public getProfileState() {
    return this.profileState.asObservable();
  }

  /**
   * Logout
   */
  public logout() {
    this.flush();
  }

  /**
   * Flush data
   */
  public flush() {
    this.savePlatforms(null);
    this.platformState.next(null);
    this.saveToken(null);
    this.updatePlatformUserProfile(undefined, undefined);
    this.initValue = null;
  }

  /**
   * Get current action id
   */
  public getCurrentActionId(): number {
    return this.platformState.getValue()?.id_action;
  }

  public getAppId(): string {
    return this.appId;
  }

  public getCurrentVersion(): string {
    return this.currentVersion;
  }

  /**
   * Validate token
   * @param token token to validate
   * @param email check email account if exist (for platform token)
   */
  public validateToken(token: string, email?: string): Observable<ReqValidateTokenInterface> {
    const query = email ? `?email=${ email }` : '';
    const headers = new HttpHeaders({
      lang: this.translate.getActiveLang() || 'en'
    });
    return this.http.get<ReqValidateTokenInterface>(`${ this.rootApi }/validate-token/${ token }${ query }`, {headers});
  }

  /**
   * Process token
   *
   * @param token
   * @param data Minimum fields: email, firstname, lastname
   */
  public processToken(token: string, data: { [key: string]: string | number | boolean }): Observable<StandardResponseInterface> {
    const body = new FormData();
    const headers = new HttpHeaders({
      lang: this.translate.getActiveLang() || 'en'
    });

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'number') {
        body.append(key, data[key].toString());
      } else if (typeof data[key] === 'string') {
        body.append(key, data[key] as string);
      } else if (typeof data[key] === 'boolean') {
        body.append(key, data[key] ? 'true' : 'false');
      }
    });

    return this.http.post<ReqValidateTokenInterface>(`${ this.rootApi }/process-token/${ token }`, body, {headers});
  }


  /**
   * Login to backend
   * @param email email for login
   * @param password password for login
   * @param tfaCode Two factor authentication code
   */
  public loginByCredentials(email: string, password: string, tfaCode?: string): Observable<InitInterface> {
    const body = new FormData();
    body.append('email', email);
    body.append('passwd', password);
    if (tfaCode) {
      body.append('tfa_code', tfaCode);
    }

    return this.http.post<TokenInterface>(`${ this.rootApi }/auth`, body, {
      withCredentials: true,
      headers: {lang: this.translate.getActiveLang() || 'en'}
    }).pipe(
      switchMap((tokenData) => {
        if (tokenData.error) {
          // TODO: Error code 4 No account exist can be turned to HTTP 404 code error instead HTTP code 200
          return throwError({error: tokenData, message: 'Request error'});
        } else {
          const tokenCast = {
            error: tokenData.error,
            message: tokenData.message,
            token: tokenData.token,
            session_token: tokenData.session_token,
            user: toUserProfile(tokenData.user)
          };
          this.saveToken(tokenCast);
          return this.initPlatform(tokenCast);
        }
      })
    );
  }

  /**
   * Login to backend by OAuth
   * @param token OAuth token
   * @param cid OAuth client id
   */
  public loginByOAuth(token: string, cid?: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token,
      lang: this.translate.getActiveLang() || 'en'
    });

    const body = new FormData();
    if (cid) {
      body.append('cid', cid);
    }

    return this.http.post<TokenInterface>(`${ this.rootApi }/auth`, body, {headers, withCredentials: true}).pipe(
      switchMap((tokenData) => {
        if (tokenData.error) {
          return throwError(tokenData.message);
        } else {
          this.saveToken(tokenData);
          return this.initPlatform(tokenData);
        }
      })
    );
  }

  /**
   * Get Token from Auth code
   * @param code
   * @param clientId
   * @param clientSecret
   * @param redirectUri
   */
  public oauthCode(code: string,
                   clientId: string,
                   clientSecret: string,
                   redirectUri: string): Observable<AuthCodeInterface> {
    const body = new FormData();
    body.append('grant_type', 'authorization_code');
    body.append('code', code);
    body.append('client_id', clientId);
    body.append('client_secret', clientSecret);
    body.append('redirect_uri', redirectUri);
    return this.http.post<AuthCodeInterface>(`${ this.rootApi }/oauth/token`, body);
  }

  /**
   * Reload 2FA code
   * @param state
   */
  public login2FAReloadCode(state: string) {
    return this.http.get<StandardResponseInterface>(`${ this.rootApi }/auth/2fa-challenge/${ state }`);
  }

  /**
   * Initialize to get platforms
   * @param token
   * @private
   */
  private initPlatform(token: TokenInterface): Observable<InitInterface> {
    if (!this.initInProgress) {

      this.initInProgress = true;

      return this.initRequest(token.token, this.translate.getActiveLang()).pipe(
        tap({
          next: (initData) => {
            this.savePlatforms(initData?.actions);

            // Update data of current platform
            const currentActionId = this.platformState.value?.id_action;
            if (currentActionId) {
              const platform = initData?.actions?.find(action => Utils.toNumber(action.id_action) === Utils.toNumber(currentActionId));
              if (platform) {
                this.platformState.next(platform);

                // Load profile
                this.loadPlatformUserProfile(true).subscribe();
              }
            }

            this.initInProgress = false;
          },
          error: () => {
            this.savePlatforms([]);

            this.initInProgress = false;
          }
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Verify session  with session token and get new tokens
   */
  verifySession() {
    if (this.authState.getValue().session_token) {
      const body = new FormData();
      body.set('code', this.authState.getValue().session_token);

      return this.http.post<TokenInterface>(`${ this.rootApi }/verifysession`, body, {
        withCredentials: true,
        headers: {lang: this.translate.getActiveLang() || 'en'}
      }).pipe(
        switchMap((tokenData) => {
          if (tokenData.error) {
            return throwError(tokenData.message);
          } else {
            tokenData.user = toUserProfile(tokenData.user);

            this.saveToken(tokenData);

            return of(tokenData);
          }
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Use to initiate an environment.
   * Return list of all user platforms.
   * @param token token get from authentication
   * @param lang language code
   * @param forceReload force reload
   */
  initRequest(token: string, lang: string, forceReload = false): Observable<InitInterface> {
    if (this.initValue && !forceReload) {
      return of(this.initValue);
    } else {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', 'Bearer ' + token);
      headers = headers.set('lang', lang);
      return this.http.post<InitInterface>(`${ this.rootApi }/init`, null, {headers}).pipe(
        tap({
          next: response => {
            if (response.error) {
              return throwError(response.message);
            } else {
              this.initValue = response;
            }
          },
          error: err => {
            console.log('[ERRApiService0003] ', err);
          }
        })
      );
    }
  }

  /**
   * Indicate if user is connected
   * Shall have token and platform defined
   * Use token for state
   */
  public isConnected(): Observable<boolean> {
    return forkJoin({
      token: this.getToken(),
      platform: this.getSelectedPlatform(),
      profile: this.getPlatformUserProfile()
    }).pipe(map(res =>
      ConnectionService.isValid(res.token) &&
      ConnectionService.isValid(res.platform) &&
      ConnectionService.isValid(res.profile)
    ));
  }

  /**
   * Indicate if user is connected (without observable)
   */
  public isConnectedValue(): boolean {
    return ConnectionService.isValid(this.authState.getValue()) &&
      ConnectionService.isValid(this.platformState.getValue()) &&
      ConnectionService.isValid(this.profileState.getValue());
  }

  /**
   * Get the token from storage
   */
  public getToken(): Observable<TokenInterface> {
    if (this.authState.getValue()) {
      return of(this.authState.getValue());
    } else {
      return from(this.storage.get('__token')).pipe(
        tap({
          next: (data: TokenInterface) => this.authState.next(data),
          error: () => this.authState.next(null)
        })
      );
    }
  }

  /**
   * Get the platform from storage
   */
  public getPlatforms(): Observable<PlatformInterface[] | undefined> {
    return from(this.storage.get('__platforms'));
  }

  /**
   * Refresh the platforms
   * @param newPlatformId
   */
  public refreshPlatformsAndUpdateId(newPlatformId: number): Observable<boolean> {
    if (!this.initInProgress) {

      this.initInProgress = true;

      return this.initRequest(this.authState.getValue()?.token, this.translate.getActiveLang(), true).pipe(
        catchError(err => {
          this.savePlatforms([]);

          this.initInProgress = false;

          return throwError(err);
        }),
        // Save platforms and update platform ID
        switchMap((initData: any) => {
          this.savePlatforms(initData?.actions);

          return this.setSelectedPlatformId(newPlatformId);
        }),
        // Load user profile
        switchMap(() => {
          return this.loadPlatformUserProfile(true);
        }),
        // End init process
        tap(() => {
          // Force refresh platform state ton run connected task in app.component.ts
          this.platformState.next(this.platformState.getValue());
          this.initInProgress = false;
        }),
        map(() => true)
      );
    } else {
      return of(null);
    }
  }

  /**
   * Get selected platform
   */
  public getSelectedPlatform(): Observable<PlatformInterface> {
    if (this.platformState.getValue()) {
      return of(this.platformState.getValue());
    } else {
      return forkJoin({
        platforms: this.getPlatforms(),
        action_id: from(this.storage.get('__platform_action_id'))
      }).pipe(
        switchMap(data => {
          if (data.platforms && data.action_id) {
            const selectedPlatform = data.platforms.find(platform => Utils.toNumber(platform.id_action) === Utils.toNumber(data.action_id));
            this.platformState.next(selectedPlatform);
            return of(selectedPlatform);
          } else {
            if (this.platformState.getValue()) {
              this.platformState.next(null);
            }
            return of(null);
          }
        })
      );
    }
  }

  /**
   * Set the selected platform
   * @param idAction
   */
  public setSelectedPlatformId(idAction?: number): Observable<PlatformInterface> {
    return new Observable<PlatformInterface>(observer => {
      if (idAction) {
        this.storage.set('__platform_action_id', idAction)?.then(/* Nothing to do */);
        this.getPlatforms().subscribe(platforms => {
          this.platformState.next(platforms.find(platform => Utils.toNumber(platform.id_action) === Utils.toNumber(idAction)));
          observer.next(this.platformState.getValue());
          observer.complete();
        });
      } else {
        this.storage.remove('__platform_action_id')?.then(/* Nothing to do */);
        observer.next(this.platformState.getValue());
        observer.complete();
      }
    });
  }

  /**
   * Update profile of token
   * @param user
   */
  public updateTokenProfile(user: UserProfileInterface) {
    const token = this.getTokenValue();

    if (Utils.toNumber(user.id_customer) === Utils.toNumber(token?.user.id_customer)) {
      token.user = toUserProfile(user);

      this.saveToken(token);

      return true;
    }

    return false;
  }

  /**
   * Get my profile
   */
  public me(): Observable<UserProfileInterface> {
    if (!this.authState.getValue() || !this.platformState.getValue()) {
      return of(null);
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + this.authState.getValue().token);
    headers = headers.set('lang', this.translate.getActiveLang());

    const body = new FormData();
    body.append('id_action', this.platformState.getValue().id_action.toString());

    return this.http.post<any>(`${ this.rootApi }/me`, body, {headers}).pipe(
      tap((data: any) => this.updatePlatformUserProfile(toUserProfile(data.me), toUserOptions(data.me))),
      map((data: any) => data.me)
    );
  }

  /**
   * Enable/Disable 2FA
   */
  public update2FA(enable = false): Observable<StandardResponseInterface> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + this.authState.getValue().token);
    headers = headers.set('lang', this.translate.getActiveLang());

    return this.http.post<StandardResponseInterface>(`${ this.rootApi }/2fa/${ enable ? 'enable' : 'disable' }`, null, {headers});
  }

  public loadPlatformUserProfile(forceUpdate = false): Observable<UserProfileInterface> {
    if (this.profileInProgress === true) {
      return this.profileState.asObservable().pipe(
        first(),
        map(data => data?.user)
      );
    } else {
      if (this.platformState.getValue() !== null && this.authState.getValue() !== null) {
        return this.getPlatformUserProfile().pipe(
          switchMap((data: UserProfileInterface) => {
            if (!data || forceUpdate) {
              this.profileInProgress = true;
              return this.me().pipe(
                tap(() => {
                  this.profileInProgress = false;
                  this.checkProfileConsistency();
                }),
              );
            } else {
              return of(data);
            }
          })
        );
      } else {
        return of(null);
      }
    }
  }

  public updatePlatformUserProfile(user?: UserProfileInterface,
                                   options?: UserOptionsInterface) {
    if (user !== undefined) {
      const tokenUser = this.getTokenValue()?.user;
      user.signatureTJS = tokenUser?.signatureTJS;

      if (
        (user.firstname !== tokenUser.firstname) ||
        (user.lastname !== tokenUser.lastname) ||
        (user.email !== tokenUser.email) ||
        (user.birthday !== tokenUser.birthday) ||
        (user.profile_bg !== tokenUser.profile_bg)
      ) {
        this.updateTokenProfile(user);
      }
    }
    this.storage.set('__platform_profile', {user, options})?.then(/* Nothing to do */);
    this.profileState.next({user, options});
  }

  public getPlatformUserProfile(): Observable<UserProfileInterface> {
    return this.getPlatformUserData('user') as Observable<UserProfileInterface>;
  }

  public getPlatformUserOptions(): Observable<UserOptionsInterface> {
    return this.getPlatformUserData('options') as Observable<UserOptionsInterface>;
  }

  private getPlatformUserData(key: 'user' | 'options'): Observable<UserProfileInterface | UserOptionsInterface> {
    if (this.profileState.getValue() && this.profileState.getValue()[key]) {
      return of(this.profileState.getValue()[key]);
    } else {
      return from(this.storage.get('__platform_profile')).pipe(
        tap((data: UserDataInterface) => this.profileState.next(data)),
        map(data => data ? data[key] : undefined)
      );
    }
  }

  public getPlatformUserProfileValue(): UserProfileInterface {
    return this.profileState.getValue()?.user;
  }

  public getPlatformUserOptionsValue(): UserOptionsInterface {
    return this.profileState.getValue()?.options;
  }

  /**
   * Update platform data
   * @param platform
   */
  public updatePlatformData(platform: PlatformInterface) {
    const curPlatform = this.getSelectedPlatformValue();

    if (Utils.toNumber(platform.id_action) === Utils.toNumber(curPlatform.id_action)) {
      this.getPlatforms().subscribe(platforms => {
        const index = platforms.findIndex(p => Utils.toNumber(p.id_action) === Utils.toNumber(platform.id_action));
        if (index !== -1) {
          platforms[index] = platform;
          this.savePlatforms(platforms);
        }
        this.platformState.next(platform);
      });
    }
  }


  /**
   * Save the token object
   */
  public saveToken(token: TokenInterface) {
    if (token) {
      this.storage.set('__token', token)?.then(/* Nothing to do */);
    } else {
      this.storage.remove('__token')?.then(/* Nothing to do */);
    }
    this.authState.next(token);
  }

  /**
   * Save platform object
   * @param platforms
   */
  public savePlatforms(platforms: PlatformInterface[]) {
    if (platforms) {
      this.storage.set('__platforms', platforms)?.then(/* Nothing to do */);
    } else {
      this.storage.remove('__platforms')?.then(/* Nothing to do */);
      this.storage.remove('__platform_action_id')?.then(/* Nothing to do */);
    }
  }

  /**
   * Get token auth
   */
  public getTokenValue() {
    return this.authState.getValue();
  }

  /**
   * Get selected platform
   */
  public getSelectedPlatformValue() {
    return this.platformState.getValue();
  }

  public saveConnectionData(email: string, password: string) {
    this.connectionData = {email, password};
  }

  public getConnectionData() {
    return this.connectionData;
  }

  /**
   * Save mobile app version & id
   * @param value App Infos
   */
  public setAppInfo(value: AppInfo) {
    this.currentVersion = value.version;
    this.appId = value.id;
  }

  /**
   * Used to log batch data
   * @param fields fields to log
   */
  public batchLogs(fields: { [key: string]: string | number | boolean }): Observable<StandardResponseInterface> {

    const body = new FormData();

    Object.keys(fields)?.forEach((key) => {
      if (typeof fields[key] === 'boolean') {
        fields[key] = fields[key] ? 1 : 0;
      }
      body.append(key, fields[key]?.toString());
    });

    return this.stdRequest(this.http.post(`${ this.rootApi }/batch-log`, body));
  }

  /**
   * Check if user a connected
   * @param authValue
   * @param platformValue
   * @param profileValue
   * @private
   */
  private checkConnectionState(authValue: TokenInterface, platformValue: PlatformInterface, profileValue: UserProfileInterface) {
    const connected = ConnectionService.isValid(authValue) &&
      ConnectionService.isValid(platformValue) &&
      ConnectionService.isValid(profileValue);

    if (connected !== this.connectionState.getValue()) {
      this.connectionState.next(connected);
    }
    // Update profile of user
    if (connected && this.lastPlatform?.id_action !== platformValue?.id_action) {
      if (!!this.lastPlatform) {
        this.loadPlatformUserProfile(true).subscribe(/* Nothing to do */);
      }
      this.lastPlatform = platformValue;
    }
  }

  /**
   * Check profile consistency
   * @private
   */
  private checkProfileConsistency() {
    const profile = this.profileState.getValue()?.user;
    if (profile !== undefined) {
      const tokenUser = this.getTokenValue()?.user;
      if (Utils.toNumber(profile.id_customer) !== Utils.toNumber(tokenUser?.id_customer)) {
        this.logout();
      }
    }
  }
}
