import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class TitleValidator {

  /**
   * Validate title is correctly defined
   */
  static isValid: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!(/^[^<>=(){}]*$/gi.test(control?.value || ''))) {
      return {'not-valid': true};
    }

    return null;
  }

}
