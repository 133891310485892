import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponsiveService } from '@common/services/responsive.service';
import { IonRouterOutlet, Platform } from '@ionic/angular';
import { StateService } from '@common/services/state.service';
import { Subscription } from 'rxjs';
import { AppHeaderButtonInterface } from '@common/components/app/header/header.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  @Input() defaultBackUri: string;

  @Input() title: string;

  @Input() sideButton: AppHeaderButtonInterface;

  @Input() withShadow = true;

  isMobile: boolean = false;
  isIos: boolean = false;
  isDashboard: boolean = false;

  backUri: string;

  private subscriptions: Subscription[] = [];

  /**
   *  Determine mobile or tablet or desktop
   *  Mobile: < md
   *  Tablet: > md && < lg
   *  Desktop: > lg
   */
  platformType = () => {
    return (this.responsiveServ.is?.md ? 'mobile' : (this.responsiveServ.is?.lg ? 'tablet' : 'desktop'));
  };

  constructor(protected router: Router,
              protected routerOutlet: IonRouterOutlet,
              protected stateServ: StateService,
              protected ionicPlatform: Platform,
              public responsiveServ: ResponsiveService) {
  }

  ngOnInit() {
    this.isIos = this.ionicPlatform.is('ios');
    this.backUri = this.getBackUri() || this.defaultBackUri;

    this.subscriptions.push(this.stateServ.isInDashboardState().subscribe(value => this.isDashboard = value));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  /**
   * Check if route is enabled
   * @param route route to check
   * @param strict if cehckt shall be strict
   */
  routeIsEnabled(route: string, strict = false) {
    if (strict) {
      return this.router.url === route;
    }
    return (this.router.url.indexOf(route) > -1);
  }

  /**
   * Can go back
   */
  canGoBack() {
    return this.routerOutlet.canGoBack() || this.backUri;
  }

  /**
   * Get back Uri
   * @private
   */
  private getBackUri() {
    return this.router.getCurrentNavigation()?.extras?.state?.backUri;
  }

}
