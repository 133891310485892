import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import {
  ChallengeFilterData,
  ChallengesData,
  ReqChallengeDetailInterface,
  ReqChallengeHistoryInterface,
  ReqChallengesInterface,
  ReqChallengeTeamMembersInterface,
  ReqChallengePerformancesInterface, ChallengeUnitType, ChallengeUpdateProgressionData, ReqChallengeHistoryListInterface
} from '@common/interfaces/api/client';
import { StandardResponseInterface } from '@common/interfaces/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Utils } from '@common/helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class ChallengesService extends HttpBaseService {
  static CHALLENGE_NB_RESULTS = 50;

  private lastChallenge: {
    result?: ReqChallengeDetailInterface,
    debut: number;
  };

  private challengesData: ReqChallengesInterface;

  /**
   * Used to get all user challenges,
   * you can display challenges from a given category by adding id_category to the /getChallenges endpoint url.
   * @param data input data
   */
  challenges(data?: ChallengesData): Observable<ReqChallengesInterface> {
    const body = new FormData();
    data.state = data.state || 'inprogress';
    if (data) {
      Object.keys(data).forEach(key => {
        body.append(key, data[key]);
      });
    }
    return this.stdRequest<ReqChallengesInterface>(
      this.http.post<ReqChallengesInterface>(`${ this.rootApi }/getChallenges`, body)
    ).pipe(
      tap(response => {
        // Cast Data
        response.challenges?.forEach(challenge => {
          challenge.main = Utils.resultToBoolean(challenge.main);

          challenge.children?.forEach(child => child.main = Utils.resultToBoolean(child.main));
        });
      }),
      tap(response => this.challengesData = response)
    );
  }

  /**
   * Used to get the ranking from a given challenge
   * @param idChallenge id of challenge
   * @param viewMode 0/1 used to display ranking where user is not member
   * @param filters filters of challenge
   * @param next load next
   */
  challenge(idChallenge: number,
            viewMode = 0,
            filters?: ChallengeFilterData[],
            next = false): Observable<ReqChallengeDetailInterface> {
    if (next === false) {
      this.lastChallenge = {result: null, debut: 0};
    } else {
      this.lastChallenge.debut += ChallengesService.CHALLENGE_NB_RESULTS;
    }

    const body = new FormData();
    body.append('debut', this.lastChallenge.debut.toString());
    body.append('limit', ChallengesService.CHALLENGE_NB_RESULTS.toString());
    body.append('view_mode', viewMode.toString());
    if (filters) {
      body.append('filters', JSON.stringify(filters));
    }
    return this.stdRequest<ReqChallengeDetailInterface>(
      this.http.post<ReqChallengeDetailInterface>(`${ this.rootApi }/getChallenge/${ idChallenge.toString() }`, body)
    ).pipe(
      tap(response => {
        // Cast Data
        if (response.challenge) {
          response.challenge.main = Utils.resultToBoolean(response.challenge.main);

          response.challenge.children?.forEach(child => child.main = Utils.resultToBoolean(child.main));
        }
        // Manage next ranking
        if (next) {
          this.lastChallenge.result.classement = this.lastChallenge.result.classement.concat(response.classement);
        } else {
          this.lastChallenge.result = response;
        }

        // Update challenge progression in local list
        this.challengesData?.challenges?.forEach(item => {
          if (Utils.toNumber(item.id_challenge) === Utils.toNumber(idChallenge)) {
            item.objectif = response?.challenge?.objectif || item.objectif;
          }
        });

      }),
      map(() => this.lastChallenge.result)
    );
  }

  /**
   * Used to update the progression of a given user from a given challenge.
   * @param params parameters:
   *   - idUser: id of the user
   *   - idChallenge: id of the challenge
   *   - value: the amount to add to the user progression
   *   - comment: comment of new adding
   *   - overwrite: boolean value, if set to true, amount will replace the current user progression instead of increment it,
   *        default is false
   *   - idTeam: id of team to update (if multi-team)
   *   - proofUrl: proof url
   *   - proofFile: proof file
   * @param progressEvent event to report progress
   */
  updateProgression(params: ChallengeUpdateProgressionData,
                    progressEvent?: BehaviorSubject<number>): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('user', params.idUser);
    body.append('id_challenge', params.idChallenge);
    body.append('amount', params.value.toFixed(4));
    body.append('overwrite', params.overwrite ? '1' : '0');
    if (params.idTeam) {
      body.append('id_team', params.idTeam);
    }
    if (params.comment) {
      body.append('comment', params.comment);
    }
    if (params.proofUrl) {
      body.append('proof_url', params.proofUrl);
    }
    if (params.proofFile) {
      body.append('proof_file', params.proofFile);
    }

    return this.uploadRequest(`${ this.rootApi }/updateprogression`, body, progressEvent);
  }

  /**
   * Used to get team members
   * @param idTeam id of the team
   * @param idChallenge id of the challenge
   */
  teamMembers(idTeam: number, idChallenge: number): Observable<ReqChallengeTeamMembersInterface> {
    const body = new FormData();
    body.append('id_team', idTeam.toString());
    body.append('id_challenge', idChallenge.toString());

    return this.stdRequest(
      this.http.post<ReqChallengeTeamMembersInterface>(`${ this.rootApi }/getTeamMembers`, body)
    );
  }

  /**
   * Used to get challenge history for progression
   * @param idChallenge id of the challenge
   */
  history(idChallenge: string): Observable<ReqChallengeHistoryInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge.toString());

    return this.stdRequest(
      this.http.post<ReqChallengeHistoryInterface>(`${ this.rootApi }/getChallenge/${ idChallenge }/history`, body)
    );
  }

  /**
   * Used to get challenge history items
   * @param idChallenge id of the challenge
   */
  historyList(idChallenge: string): Observable<ReqChallengeHistoryListInterface> {
    const body = new FormData();
    body.append('id_challenge', idChallenge.toString());

    return this.stdRequest(
      this.http.post<ReqChallengeHistoryInterface>(`${ this.rootApi }/getChallenge/${ idChallenge }/historyList`, body)
    );
  }

  /**
   * Used to get performances of challenge
   * @param idChallenge id of challenge
   * @param unit unit of challenge
   * @param filters filters of challenge
   */
  performances(idChallenge: number,
               unit: ChallengeUnitType = 'day',
               filters?: ChallengeFilterData[]): Observable<ReqChallengePerformancesInterface> {
    const body = new FormData();
    body.append('unit', unit);
    if (filters?.length > 0) {
      body.append('filters', JSON.stringify(filters));
    }
    return this.stdRequest(
      this.http.post<ReqChallengePerformancesInterface>(`${ this.rootApi }/challenge/${ idChallenge.toString() }/performances`, body)
    );
  }

  /**
   * Used to get challenge points for La foret client only
   */
  pointsChallenge(): Observable<any> {
    return this.stdRequest(
      this.http.post<any>(`${ this.rootApi }/Laforet/getPointsChallenges`, null)
    );
  }

}
