import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { AlertController, NavController, Platform } from '@ionic/angular';
import { ConnectionService } from '@common/services/connection.service';
import { CommonService } from '@common/services/common.service';
import { ThemeService } from '@common/services/theme.service';
import { CguAgreementController } from '@common/components/modals/cgu-agreement';
import { TeamService } from '@common/services/team.service';
import { TranslocoService } from '@ngneat/transloco';
import { ResponsiveService } from '@common/services/responsive.service';
import { DateAdapter } from '@angular/material/core';
import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { PlatformInterface, PushNotificationInterface } from '@common/interfaces/api';

import { Intercom } from 'ng-intercom';
import { Utils } from '@common/helpers/utils';
import { NotificationsUtils, NotificationsService } from '@common/services/notifications';
import { TalkService } from '@common/components/app/talk';
import { AccessControlService } from '@common/services/access-control';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NetworkStatusService } from '@common/components/utils/network-status';
import { ModelsService } from '@common/services/api/client';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { Md5 } from 'ts-md5';
import { OnboardingService } from '@common/services/onboarding.service';
import { environment } from '@environments/environment';
import { NgxEditorConfig } from 'ngx-editor';
import { ProfileFieldsController } from '@common/components/app/profile-fields';
import { DashboardConsistencyFieldsController } from '@app/dashboard/_consistency-fields';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { StateService } from '@common/services/state.service';


declare var batch;

export let ngxEditorLocals: NgxEditorConfig['locals'] = {};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  connected: boolean = false;
  isDesktop: boolean = false;
  isInDashboard: boolean = false;

  platform: PlatformInterface;

  private isIntercomUsed: boolean = false;
  private isBatchInitialized: boolean = false;
  private isAppUpdateDisplayed: boolean = false;
  private isAppRatingDisplayed: boolean = false;
  private isProfileFieldsChecked: boolean = false;
  private isConsistencyFieldsChecked: boolean = false;
  private isTermsDisplayed: boolean = false;

  private appId: string = '';
  private subscriptions: Subscription[] = [];
  private lastIdPlatform: number;
  private batchLastData = {
    idPlatform: null,
    idUser: null,
    installationId: null
  };

  constructor(
    private ionicPlatform: Platform,
    private connectionServ: ConnectionService,
    private navCtrl: NavController,
    private commonServ: CommonService,
    private themeServ: ThemeService,
    private cguAgreementCtrl: CguAgreementController,
    private alertCtrl: AlertController,
    private teamServ: TeamService,
    private translateServ: TranslocoService,
    private talkServ: TalkService,
    private responsiveServ: ResponsiveService,
    private dateAdapter: DateAdapter<any>,
    private intercom: Intercom,
    private notificationsServ: NotificationsService,
    private accessServ: AccessControlService,
    private route: ActivatedRoute,
    private router: Router,
    private networkStatusServ: NetworkStatusService,
    private modelsServ: ModelsService,
    private onboardingServ: OnboardingService,
    private profileFieldsCtrl: ProfileFieldsController,
    private consistencyFieldsCtrl: DashboardConsistencyFieldsController,
    private stateServ: StateService
  ) {
  }

  ngOnInit() {
    this.connected = false;
    this.ionicPlatform.ready().then(() => {
      this.preInitApp()?.then(/* Nothing to do */);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.networkStatusServ.destroy();
  }

  async preInitApp() {

    // Define scheme and App Id for native app
    if (this.ionicPlatform.is('capacitor')) {
      const appInfo = await App.getInfo();
      if (appInfo) {
        this.appId = appInfo?.id;
        this.themeServ.defineScheme(this.appId);
        this.connectionServ.setAppInfo(appInfo);
      }
    }

    // Apply dark mode is current theme used
    await this.themeServ.determineOrDefineDarkMode()?.then(/* Nothing to do */);
    // Setup subdomain or scheme theme
    this.themeServ.setupTheme(this.commonServ.getSubDomain());
    this.checkAppUpdate();
  }

  /**
   * Check for app major update.
   * Major update require update the app
   */
  checkAppUpdate() {
    this.commonServ.checkVersionUpdate().then(result => {
      if (result.hasUpdate && result.isMandatoryUpdate) {
        // Hide SplashScreen after 3 seconds
        setTimeout(() => {
          this.navCtrl.navigateRoot(['/update'])?.then(/* Nothing to do */);
          SplashScreen.hide({fadeOutDuration: 300}).then(/*Nothing to do*/);
        }, 4000);
      } else {
        this.initApp();
      }
    }).catch(() => this.initApp());
  }

  initApp() {
    this.connectionServ.init();
    this.initPushNotifications();
    this.initNativeSettings();
    this.buildResponsive();
    this.networkStatusServ.init().then(/* Nothing to do */);
    this.initIosKeyboard();
    this.setupTheme();
    this.subscriptions.push(this.stateServ.isInDashboardState().subscribe(value => this.isInDashboard = value));

    /**
     * Manage services/components initialization when user is connected / disconnected.
     */
    this.subscriptions.push(this.connectionServ.getConnectionState().subscribe(connected => {
      this.connected = connected;

      if (connected) {
        // Initialize permissions
        this.accessServ.onPlatformChange();

        // Init if connected
        this.manageCgu();
        this.manageHelperInQueue();
        this.manageTalkJsInQueue();
        this.checkForProfileFields();
        this.checkForConsistencyFields();
        this.checkForAppUpdateInQueue();
        this.checkForAppRating();
        this.superviseBatchAndFireBase(false)?.then(/* Nothing to do */);
        this.teamServ.init();
        this.notificationsServ.init().then(/* Nothing to do */);
      } else {
        // Flush or de-init if not connected
        this.superviseBatchAndFireBase(true)?.then(/* Nothing to do */);
        this.manageHelperInQueue(false);
        this.manageTalkJsInQueue(false);
        this.notificationsServ.flush();
        this.onboardingServ.destroy();
        this.resetPlatformFlags();
      }

      this.setupLogs(connected)?.then(/* Nothing to do */);
    }));

    /**
     * Manage services/components platform change tasks
     */
    this.subscriptions.push(this.connectionServ.getPlatformState().subscribe(platform => {
      // For task need a real platform change
      if (platform && this.platform?.id_action !== platform?.id_action) {
        this.resetPlatformFlags();
      }
      // For task where done action on any platform change
      this.accessServ.onPlatformChange();
      this.platform = platform;
      // For task where platform shall be defined and user connected
      if (platform && this.connected) {
        this.connectionServ.getPlatformUserProfile().subscribe(() => {
          this.manageCgu();
          this.manageHelperInQueue();
          this.manageTalkJsInQueue();
          this.checkForProfileFields();
          this.checkForConsistencyFields();
          this.checkForAppUpdateInQueue();
          this.checkForAppRating();
          this.checkForPlatformIdConsistency();
          this.checkForUrlWithoutToken();
          this.setupLogs(true)?.then(/* Nothing to do */);

          // For tasks where platform shall be defined and different from previous
          if (this.lastIdPlatform !== platform.id_action) {
            this.talkServ.onPlatformChange();
            this.notificationsServ.onPlatformChange(platform);

            this.lastIdPlatform = platform?.id_action;
          }
        });
      }
    }));

    this.manageTranslationInit().then(/* nothing to do */);
    this.setupNavigationEndActions();

    if (this.ionicPlatform.is('capacitor')) {
      setTimeout(() => {
        SplashScreen.hide({fadeOutDuration: 300}).then(/*Nothing to do*/);
      }, 5000);
    }
  }

  /**
   * Initialize settings for native
   */
  initNativeSettings() {
    if (this.ionicPlatform.is('capacitor')) {
      StatusBar.show().then(/* Nothing to do */);
    }
  }

  /**
   * Setup NavigationEnd actions
   * @private
   */
  private setupNavigationEndActions() {
    this.subscriptions.push(this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        distinctUntilChanged()
      ).subscribe((route: any) => {

        // Login Theme
        let hasLinkMatch: boolean = false;
        ['/login', '/signup', '/signin', '/signup-continue', '/password-reset', '/set-password'].forEach(val => {
          if (route.url && (route.url.indexOf(val) > -1)) {
            hasLinkMatch = true;
          }
        });

        if (hasLinkMatch) {
          this.themeServ.applyLoginTheme();
        } else {
          this.themeServ.removeLoginTheme();
        }

        this.themeServ.setLoginEnvironment(hasLinkMatch);

        // Crashlytics
        if (this.ionicPlatform.is('capacitor')) {
          FirebaseCrashlytics.setCustomKey({
            key: 'page',
            value: route.url,
            type: 'string'
          }).then(/* Nothing to do */);
        }

        // Dashboard supervision
        const isInDashboardRoute = route.url?.indexOf('/dashboard') > -1;
        if (!this.commonServ.canAccessDashboard() && isInDashboardRoute) {
          this.navCtrl.navigateRoot(['/'])?.then(/* Nothing to do */);
        } else if (isInDashboardRoute && !this.stateServ.isInDashboard()) {
          this.stateServ.setInDashboardValue(true);
        } else if (!isInDashboardRoute && this.stateServ.isInDashboard()) {
          this.stateServ.setInDashboardValue(false);
        }
      }));
  }

  /**
   * Initialize logs
   * @param connected
   */
  private async setupLogs(connected = false) {
    if (this.ionicPlatform.is('capacitor')) {
      const scheme = this.themeServ.getCurrentScheme();
      const deviceInfo = await Device.getInfo();
      const deviceUuid = await Device.getId();
      const appInfo = await App.getInfo();
      const profile = this.connectionServ.getPlatformUserProfileValue();
      const platform = this.connectionServ.getSelectedPlatformValue();
      const batchInstallationId = (await batch?.user?.getInstallationID()) || null;
      const batchCustomerUserId = (await batch?.user?.getIdentifier()) || '';
      const batchPushToken = (await batch?.push?.getLastKnownPushToken()) || '';

      // Save Last User Id for logout log
      const userId: number = Utils.toNumber(profile?.id_customer) || 0;
      if (userId > 0) {
        this.batchLastData.idUser = userId;
      }

      if (batchInstallationId !== null) {
        this.batchLastData.installationId = batchInstallationId;
      }

      if (platform !== null) {
        this.batchLastData.idPlatform = Utils.toNumber(platform.id_action);
      }

      const infos: { [key: string]: any } = {
        uuid: deviceUuid.identifier,
        deviceName: deviceInfo.name,
        deviceModel: deviceInfo.model,
        devicePlatform: deviceInfo.platform,
        deviceOS: deviceInfo.operatingSystem,
        deviceOSVersion: deviceInfo.osVersion,
        deviceManufacturer: deviceInfo.manufacturer,
        deviceWebviewVersion: deviceInfo.webViewVersion,
        appId: appInfo.id,
        appName: appInfo.name,
        appVersion: appInfo.version,
        userId: this.batchLastData.idUser || null,
        platformId: this.batchLastData.idPlatform || null,
        batchIdAndroid: scheme.androidBatchId,
        batchIdIos: scheme.iosBatchId,
        batchInstallationId: this.batchLastData.installationId || batchInstallationId || null,
        batchCustomerUserId,
        batchPushToken,
        connected
      };

      this.connectionServ.batchLogs(infos).subscribe(/* Nothing to do */);
    }
  }

  /**
   * Initialize Push Notifications
   */
  initPushNotifications() {
    if (this.ionicPlatform.is('capacitor')) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register()?.then(/* Nothing to do */);
          const scheme = this.themeServ.getCurrentScheme();
          batch.setConfig({
            androidAPIKey: scheme.androidBatchId,
            iOSAPIKey: scheme.iosBatchId
          });
          batch.start();

          if (this.ionicPlatform.is('ios')) {
            batch.push.requestNotificationAuthorization();
            batch.push.refreshToken();
            batch.push.setiOSShowForegroundNotifications(true);
          }

          this.isBatchInitialized = true;
        } else {
          // Show some error
        }
      });

      // PushNotifications.addListener('registration', (token: Token) => {
      //   console.log('Push registration success, token: ' + token.value);
      // });
      //
      // PushNotifications.addListener('registrationError', (error: any) => {
      //   console.log('Error on registration: ' + JSON.stringify(error));
      // });

      PushNotifications.addListener(
        'pushNotificationReceived',
        (pushNotification: PushNotificationSchema) => {
          const notification = JSON.parse(pushNotification.data.roadoo_notification) as PushNotificationInterface;
          if (notification.notification_count !== undefined) {
            const notificationCount = Utils.toNumber(notification.notification_count);
            this.notificationsServ.setNativeIconNotificationsBadge(notificationCount);
          }
        }
      );

      document.addEventListener('batchPushReceived', (e: any) => {
        const pushPayload = e.payload;
        // Process the payload as you wish here
        const notification = pushPayload.roadoo_notification as PushNotificationInterface;
        const platform = this.connectionServ.getSelectedPlatformValue();

        const navTo = (pf: PlatformInterface, nf: PushNotificationInterface) => {
          const route = NotificationsUtils.getRoute(nf.type, nf.reference_id, !!pf.is_admin);
          if (route) {
            this.navCtrl.navigateForward(route.url, route.options)?.then(/* Nothing to do */);
          }
        };

        if (platform.id_action !== notification.id_action) {
          this.subscriptions.push(this.connectionServ.setSelectedPlatformId(notification.id_action).subscribe(response => {
            navTo(response, notification);
          }));
        } else {
          navTo(platform, notification);
        }
      }, false);

    }
  }

  /**
   * Supervise batch and firebase
   * @param disconnected
   * @private
   */
  private async superviseBatchAndFireBase(disconnected = false) {
    if (this.ionicPlatform.is('capacitor')) {
      const user = this.connectionServ.getPlatformUserProfileValue();
      const userId = user?.id_customer?.toString() || undefined;

      // Crashlytics
      if (userId !== undefined) {
        await FirebaseCrashlytics.setUserId({userId});

        await FirebaseCrashlytics.setCustomKey({
          key: 'userId',
          value: userId,
          type: 'string'
        });

        await FirebaseCrashlytics.setCustomKey({
          key: 'userData',
          value: user.firstname + ' ' + user.lastname + ' (' + user.email + ')',
          type: 'string'
        });
      }
      await FirebaseCrashlytics.setEnabled({enabled: true});

      // Batch
      if (this.isBatchInitialized) {
        // @doc https://doc.batch.com/ios/advanced/opt-out/#opting-in
        if (disconnected) {
          batch.optOut();
        } else {
          batch.optIn();
          batch.start();
        }

        // @doc https://doc.batch.com/cordova/custom-data/customid/
        const batchIdentifier = (userId && !disconnected) ? Md5.hashStr(userId).toUpperCase() : null;
        await batch?.user.getEditor()
          .setIdentifier(batchIdentifier)
          .save();
      }
    }
  }

  /**
   * Setup colors theme according platform or scheme
   */
  setupTheme() {
    const scheme = this.themeServ.getCurrentScheme();
    this.subscriptions.push(this.connectionServ.getPlatformState().subscribe(platform => {
      // Colors Theme
      this.themeServ.setupTheme(this.commonServ.getSubDomain());

      if (this.themeServ.isLoginEnvironmentActive()) {
        this.themeServ.applyLoginTheme();
      }

      // Logo
      if (platform && scheme?.logoPath && !platform.logo) {
        // Apply scheme logo if not available for platform
        platform.logo = scheme.logoPath;
      }
    }));
  }

  /**
   * Manage TalkJs activation
   * @param enable
   * @private
   */
  private manageTalkJsInQueue(enable: boolean = true) {
    // Add task to queue
    this.commonServ.addToQueue(new Observable(observer => {
      this.manageTalkJs(enable);
      observer.next();
      observer.complete();
    }), 'manageTalkJs');
  }

  private manageTalkJs(enable: boolean = true) {
    if (enable && this.platform && this.connected) {
      this.talkServ.createCurrentSession().then(/* Nothing to do */);
    } else {
      this.talkServ.destroyCurrentSession().then(/* Nothing to do */);
    }
  }

  /**
   * Manage CGU
   */
  private manageCgu() {
    if (this.platform && this.connected && !this.isTermsDisplayed && this.cguAgreementCtrl.shallBeShow()) {
      // Disable all other tasks
      this.manageHelper(false);
      this.manageTalkJs(false);
      this.isTermsDisplayed = true;
      this.commonServ.addToQueue(this.cguAgreementCtrl.showAsObs({idAction: this.connectionServ.getSelectedPlatformValue()?.id_action}, choice => {
        if (choice) {
          this.navCtrl.navigateRoot(['/'])?.then(/* Nothing to do */);
        } else {
          this.connectionServ.logout();
          this.navCtrl.navigateRoot(['/login'])?.then(/* Nothing to do */);
        }
      }), 'manageCgu');
    }
  }

  /**
   * Manage translation at initialization
   * - Persist new language in cookie on change
   * - Apply cookie language if defined
   */
  async manageTranslationInit() {
    const lang = await this.commonServ.getLang();

    if (this.translateServ.getAvailableLangs().indexOf(lang as any) > 0) {
      this.translateServ.setActiveLang(lang);
      this.dateAdapter.setLocale(lang);
      this.talkServ.setLocale(lang);
    }

    this.subscriptions.push(this.translateServ.langChanges$.subscribe(newLang => {
      this.commonServ.setLang(newLang);
      this.dateAdapter.setLocale(newLang);
      this.talkServ.setLocale(newLang);
      this.modelsServ.reset();
      setTimeout(() => {
        this.updateEditorLocals(newLang);
      }, 2000);
    }));
  }

  /**
   * Update locals of NgxEditor
   * @param lang
   * https://github.com/sibiraj-s/ngx-editor/issues/354#issuecomment-878009003
   */
  private updateEditorLocals(lang: string) {
    ngxEditorLocals.bold = this.translateServ.translate('components.editor-input.bold', {}, lang);
    ngxEditorLocals.italic = this.translateServ.translate('components.editor-input.italic', {}, lang);
    ngxEditorLocals.underline = this.translateServ.translate('components.editor-input.underline', {}, lang);
    ngxEditorLocals.align_left = this.translateServ.translate('components.editor-input.align-left', {}, lang);
    ngxEditorLocals.align_right = this.translateServ.translate('components.editor-input.align-right', {}, lang);
    ngxEditorLocals.align_center = this.translateServ.translate('components.editor-input.align-center', {}, lang);
    ngxEditorLocals.align_justify = this.translateServ.translate('components.editor-input.align-justify', {}, lang);
    ngxEditorLocals.insertLink = this.translateServ.translate('components.editor-input.insert-link', {}, lang);
    ngxEditorLocals.removeLink = this.translateServ.translate('components.editor-input.remove-link', {}, lang);
    ngxEditorLocals.text_color = this.translateServ.translate('components.editor-input.text-color', {}, lang);
    ngxEditorLocals.openInNewTab = this.translateServ.translate('components.editor-input.openInNewTab', {}, lang);
    ngxEditorLocals.altText = this.translateServ.translate('components.editor-input.altText', {}, lang);
    ngxEditorLocals.title = this.translateServ.translate('components.editor-input.title', {}, lang);
    ngxEditorLocals.text = this.translateServ.translate('components.editor-input.text', {}, lang);
    ngxEditorLocals.insert = this.translateServ.translate('components.editor-input.insert', {}, lang);
  }

  /**
   * Build responsive
   */
  @HostListener('window:resize')
  buildResponsive() {
    this.responsiveServ.buildResponsive();
    this.talkServ.onResponsiveChange();

    this.isDesktop = !(this.responsiveServ.is?.lg);
  }


  /**
   * Check for minor update and show popup
   */
  private checkForAppUpdateInQueue() {
    if (this.platform && this.connected && !this.isAppUpdateDisplayed) {
      this.isAppUpdateDisplayed = true;
      this.commonServ.addToQueue(new Observable(observer => {
        const version = this.commonServ.getVersion();
        const profile = this.commonServ.getProfile();
        if (version.hasUpdate && !version.isMandatoryUpdate) {
          setTimeout(() => {
            this.promptUpdateMessage().then(() => {
              observer.next();
              observer.complete();
            });
          }, 3000);
          // If SUPER_ADMIN_USER, check server version consistency
        } else if (this.platform.is_admin && profile?.email?.indexOf('@maslo.app') > -1 && version.shallUpdateServerVersion) {
          setTimeout(() => {
            this.promptUpdateServerMessage().then(() => {
              observer.next();
              observer.complete();
            });
          }, 1000);
        } else {
          observer.next();
          observer.complete();
        }
      }));
    }
  }

  private promptUpdateServerMessage(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      let isMandatoryVersionChecked: boolean = false;
      const version = this.commonServ.getVersion();
      const alert = await this.alertCtrl.create({
        message: this.translateServ.translate('security.update-server.message', {
          currentVersion: version.current,
          serverVersion: version.available
        }),
        header: this.translateServ.translate('security.update-server.title'),
        backdropDismiss: true,
        keyboardClose: true,
        inputs: [
          {
            type: 'checkbox',
            name: 'mandatory',
            label: this.translateServ.translate('security.update-server.mandatory-label'),
            checked: false,
            handler: (res) => isMandatoryVersionChecked = res.checked
          }
        ],
        buttons: [{
          text: this.translateServ.translate('buttons.cancel'),
          cssClass: 'app-color-danger',
          role: 'cancel',
          handler: () => {
            resolve(false);
          }
        }, {
          text: this.translateServ.translate('security.update.button'),
          handler: () => {
            this.commonServ.getGeneralService()
              .setAppsVersion(version.current, isMandatoryVersionChecked)
              .subscribe(() => resolve(true));
          }
        }]
      });

      alert.present();
    });
  }

  private promptUpdateMessage(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      const alert = await this.alertCtrl.create({
        message: this.translateServ.translate('security.update.minor-message'),
        header: this.translateServ.translate('security.update.title'),
        backdropDismiss: true,
        keyboardClose: true,
        buttons: [{
          text: this.translateServ.translate('buttons.cancel'),
          cssClass: 'app-color-danger',
          role: 'cancel',
          handler: () => {
            resolve(false);
          }
        }, {
          text: this.translateServ.translate('security.update.button'),
          handler: () => {
            this.commonServ.openAppInStore();
            resolve(true);
          }
        }]
      });

      alert.present();
    });
  }

  /**
   * Check for app rating
   * @private
   */
  private checkForAppRating() {
    if (this.platform && this.connected && !this.isAppRatingDisplayed) {
      setTimeout(() => {
        this.commonServ.promptAppRating().then(displayed => {
          this.isAppRatingDisplayed = displayed;
        });
      }, 20000);
    }
  }

  /**
   * Check for profile fields consistency
   * @private
   */
  private checkForProfileFields() {
    if (this.platform && this.connected && !this.isProfileFieldsChecked) {
      this.commonServ.addToQueue(this.profileFieldsCtrl.checkForProfileFields(this.commonServ.getProfile()?.id_customer));
      this.isProfileFieldsChecked = true;
    }
  }

  private checkForConsistencyFields() {
    if (this.platform && this.connected && this.platform.is_admin && !this.isConsistencyFieldsChecked) {
      this.commonServ.addToQueue(this.consistencyFieldsCtrl.checkForConsistencyFields());
      this.isConsistencyFieldsChecked = true;
    }
  }

  /**
   * Manage intercom or private assistance according platform is_admin
   * Only for desktop
   * @private
   */
  private manageHelperInQueue(visible: boolean = true) {
    // Not need intercom helper for mobile devices
    if (!this.ionicPlatform.is('capacitor')) {
      // Add task to queue
      this.commonServ.addToQueue(new Observable(observer => {
        this.manageHelper(visible);
        observer.next();
        observer.complete();
      }), 'manageHelper');
    }
  }

  private manageHelper(visible = true) {
    // If option is enabled
    if (!this.accessServ.permissionsForClient().settings?.isHideHelpAdmin) {
      // Display only if required and is desktop screen and platform is set
      if (visible && this.platform && this.connected && !this.responsiveServ.is?.lg) {
        if (this.platform?.is_admin) {
          this.manageIntercom();
        } else {
          this.manageIntercom(false);
        }
      } else {
        this.manageIntercom(false);
      }
    }
  }

  /**
   * Initialize intercom
   */
  private manageIntercom(visible = true) {

    if (!environment.intercomAppId) {
      this.isIntercomUsed = false;
      return;
    }

    // Show Intercom
    if (visible) {
      if (!this.isIntercomUsed) {

        const profile = this.connectionServ.getPlatformUserProfileValue();

        if (profile) {

          // Replace Intercom icon for mobile
          /* RDFR-118: Not need for mobile
          const replaceIcon = () => {
            if (this.responsiveServ.isMobile) {
              let el = document.querySelector('.intercom-lightweight-app')?.firstChild as HTMLDivElement;
              if (!el) {
                el = document.querySelector('.intercom-app')?.querySelector('.intercom-launcher-frame') as HTMLIFrameElement;
              }
              if (el) {
                el.style.bottom = '65px';
                el.style.right = '5px';
              }
            }
          };
           */

          this.intercom.boot({
            email: profile.email,
            user_id: Utils.toString(profile.id_customer),
            widget: {
              activator: '#intercom'
            }
          });

          this.isIntercomUsed = true;

          /* RDFR-118: Not need for mobile
          this.intercom.onHide(() => {
            replaceIcon();
          });

          // Let intercom initialized to replace icon
          setTimeout(() => {
            replaceIcon();
          }, 500);

           */
        }
      }
    } else if (this.isIntercomUsed) {
      this.intercom.shutdown();
      this.isIntercomUsed = false;
    }
  }

  /**
   * Check if requested platform id is the defined platform if
   * @private
   */
  private checkForPlatformIdConsistency() {
    const requestedPlatformIdEnc = this.route.snapshot.queryParams.pid;
    const requestedPlatformId = requestedPlatformIdEnc ? Utils.toNumber(window.atob(requestedPlatformIdEnc)) : 0;
    if (requestedPlatformId > 0 && requestedPlatformId !== Utils.toNumber(this.platform.id_action)) {
      this.subscriptions.push(this.connectionServ.setSelectedPlatformId(requestedPlatformId).subscribe());
    }
  }

  /**
   * Check if UR contains token and pid. Remove them in this case.
   * Shall be called when platform is connected
   * @private
   */
  private checkForUrlWithoutToken() {
    const queryParams = Object.assign({}, this.route.snapshot.queryParams);

    if (queryParams.pid && queryParams.token) {
      queryParams.pid = undefined;
      queryParams.token = undefined;
      const urlRoot = this.router.url?.split('?').reverse().pop();

      this.navCtrl.navigateRoot([urlRoot], {queryParams, replaceUrl: true}).then(/* Nothing to do */);
    }
  }

  /**
   * Reset flags (Shall be called on platform change)
   * @private
   */
  private resetPlatformFlags() {
    this.isTermsDisplayed = false;
    this.isProfileFieldsChecked = false;
  }

  /**
   * Initialize autoscroll on keyboard open for ios
   * @private
   */
  private initIosKeyboard() {
    if (this.ionicPlatform.is('ios') && this.ionicPlatform.is('capacitor')) {
      Keyboard.addListener('keyboardDidShow', kbInfo => {
        const safeArea = 40;
        const documentInfo = document.activeElement.getBoundingClientRect();
        const offset1 = documentInfo.top;
        if (offset1 > window.innerHeight - kbInfo.keyboardHeight - safeArea) {
          document.activeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      }).then(/* Nothing to do */);
    }
  }

}
