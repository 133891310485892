import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccessControlService, PermissionsForClientInterface } from '@common/services/access-control';
import { Subscription } from 'rxjs';
import { ConnectionService } from '@common/services/connection.service';
import { IonPopover, NavController, Platform } from '@ionic/angular';
import { BadgeNotificationsEventsInterface, NotificationsService } from '@common/services/notifications';

interface MenuItem {
  iconActive: string;
  icon: string;
  url: string;
  active: () => boolean;
  badge?: () => boolean;
  nameKey: string;
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class AppMainMenuComponent implements OnInit, OnDestroy {
  @ViewChild('otherMenusPopover', {static: false}) otherMenusPopover: IonPopover;

  @Input() position: 'header' | 'footer' = 'header';

  mainMenus: MenuItem[] = [];
  otherMenus?: MenuItem[];

  notificationsCount = 0;

  isIos: boolean = false;

  badgeEvents: BadgeNotificationsEventsInterface;

  private subscriptions: Subscription[] = [];
  private platformId?: number;

  isOtherMenuActive = () => {
    let result = false;

    this.otherMenus.forEach(menu => {
      if (menu.active()) {
        result = true;
      }
    });

    return result;
  };

  hasOtherMenuEvents = () => {
    let result = false;

    this.otherMenus.forEach(menu => {
      if (menu.badge && menu.badge()) {
        result = true;
      }
    });

    return result;
  };

  constructor(protected router: Router,
              protected navCtrl: NavController,
              protected accessServ: AccessControlService,
              protected ionicPlatform: Platform,
              protected connectionServ: ConnectionService,
              protected notificationsServ: NotificationsService) {
  }

  ngOnInit() {
    this.isIos = this.ionicPlatform.is('ios');

    if (this.position === 'footer') {
      this.subscriptions.push(this.notificationsServ.getInAppNotificationsUserCountState().subscribe(val => this.notificationsCount = val));
    }

    // Manage badge flags
    this.subscriptions.push(this.notificationsServ.eventsState().subscribe(events => this.badgeEvents = events));

    this.subscriptions.push(this.accessServ.permissionsForClientState().subscribe(permissions => {
      if (permissions.platform?.id_action !== this.platformId) {
        this.platformId = permissions.platform?.id_action;
        this.buildMenu(permissions);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  buildMenu(permissions: PermissionsForClientInterface) {
    let nbEnabled = 0;
    if (permissions.isFeedEnabled) {
      nbEnabled++;
    }
    if (permissions.isChallengesEnabled) {
      nbEnabled++;
    }
    if (permissions.isStoreEnabled) {
      nbEnabled++;
    }

    this.mainMenus = [];
    this.otherMenus = undefined;
    const menus: MenuItem[] = [];

    // For desktop, shall have at least 2 pages
    if ((nbEnabled > 1) || (this.position === 'footer')) {

      if (permissions.isFeedEnabled) {
        menus.push({
          iconActive: 'reader',
          icon: 'reader-outline',
          url: '/feed',
          active: () => {
            return this.routeIsEnabled('/feed') || this.routeIsEnabled('/', true);
          },
          nameKey: 'menu.home',
          badge: () => this.badgeEvents.feedFlag
        });
      }

      if (permissions.isChallengesEnabled) {
        menus.push({
          iconActive: 'podium',
          icon: 'podium-outline',
          url: '/challenges',
          active: () => {
            return this.routeIsEnabled('/challenge');
          },
          nameKey: 'menu.challenge',
          badge: () => this.badgeEvents.challengesFlag
        });
      }

      if (permissions.isStoreEnabled) {
        menus.push({
          iconActive: 'storefront',
          icon: 'storefront-outline',
          url: '/e-store',
          active: () => {
            return this.routeIsEnabled('/e-store');
          },
          nameKey: 'menu.shop',
        });
      }

      if (permissions.isQuizEnabled) {
        menus.push({
          iconActive: 'extension-puzzle',
          icon: 'extension-puzzle-outline',
          url: '/quizzes',
          active: () => {
            return this.routeIsEnabled('/quiz');
          },
          nameKey: 'dashboard.menu.quiz',
          badge: () => this.badgeEvents.quizzesFlag
        });
      }

      if (permissions.isFormsEnabled) {
        menus.push({
          iconActive: 'rd-form',
          icon: 'rd-form-outline',
          url: '/forms',
          active: () => {
            return this.routeIsEnabled('/form');
          },
          nameKey: 'dashboard.menu.forms',
          badge: () => this.badgeEvents.formsFlag
        });
      }

      // --roadbox--
      if (permissions.isRoadboxEnabled) {
        menus.push({
          iconActive: 'library',
          icon: 'library-outline',
          url: '/library',
          active: () => {
            return this.routeIsEnabled('/library');
          },
          nameKey: 'dashboard.menu.roadbox',
          badge: () => this.badgeEvents.roadboxFlag
        });
      }

      const nbMainMenus = 4;

      if (menus.length > nbMainMenus) {
        this.mainMenus = menus.slice(0, nbMainMenus - 1);
        this.otherMenus = menus.slice(nbMainMenus - 1);
      } else {
        this.mainMenus = menus;
        this.otherMenus = undefined;
      }
    }
  }


  /**
   * Check if route is enabled
   * @param route route to check
   * @param strict if check shall be strict
   */
  routeIsEnabled(route: string, strict = false) {
    if (strict) {
      return this.router.url === route;
    }
    return this.router.url.startsWith(route);
  }

  showOtherMenusPopover(event: any) {
    this.otherMenusPopover?.present(event).then(/* Nothing to do */);
  }
}
