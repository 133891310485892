import { Injectable } from '@angular/core';
import {
  AddToCartData,
  CartInterface, DeliveryData,
  EStoreCombinationInterface,
  EStoreProductInterface,
  ReqCartInterface, ReqCountriesInterface, ReqValidateCartInterface, StandardPostInterface
} from '@common/interfaces/api/client';
import { StandardResponseInterface } from '@common/interfaces/api';
import { HttpBaseService } from '../http-base.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { buildDeliverable } from '@common/helpers/e-store.utils';

@Injectable({providedIn: 'root'})
export class CartService extends HttpBaseService {

  private hasChanges = false;
  private isSynchronized = false;
  private syncInProgress = false;
  private cartState: BehaviorSubject<CartInterface> = new BehaviorSubject<CartInterface>(undefined);
  private isCartReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Get the number of products
   */
  getNbProducts(): Observable<number> {
    return this.cartState.asObservable().pipe(
      map(value => value?.nb_products || 0)
    );
  }

  /**
   * Indicate if cart is ready
   */
  isReady() {
    return this.isCartReady.getValue();
  }

  /**
   * Return the ready state
   */
  isReadyState() {
    return this.isCartReady.asObservable();
  }

  /**
   * Has delivery localization ?
   */
  hasDeliveryLocalization(): Observable<boolean> {
    const checkDeliveryData = (data) => {
      return data?.delivery?.postcode?.length > 0;
    };
    if (!this.isCartReady.getValue()) {
      return this.isCartReady.asObservable().pipe(
        filter(value => value === true),
        map(() => checkDeliveryData(this.cartState.getValue()))
      );
    }
    return of(checkDeliveryData(this.cartState.getValue()));
  }

  /**
   * Get local cart
   * @param sync force to sync
   * @param syncChanges sync only if has changes
   */
  getLocalCart(sync = false, syncChanges = false): Observable<CartInterface> {
    if (
      (!this.cartState.getValue() || sync || (syncChanges && this.hasChanges)) &&
      !this.syncInProgress
    ) {
      this.syncCart(true).subscribe();
    }

    return this.cartState.asObservable();
  }

  /**
   * Get countries
   */
  countries(): Observable<ReqCountriesInterface> {
    return this.stdRequest(this.http.post<ReqCountriesInterface>(`${ this.rootApi }/getcountries`, null));
  }

  /**
   * Get the delivery address
   */
  getDeliveryAddress() {
    return this.stdRequest(this.http.post<StandardPostInterface>(`${ this.rootApi }/getdeliveryaddress`, null));
  }

  /**
   * Set the delivery address
   * @param data
   */
  setDeliveryAddress(data: DeliveryData): Observable<StandardPostInterface> {
    return this.deliveryRequest(data, `${ this.rootApi }/setdeliveryaddress`);
  }

  /**
   * Get the delivery localization
   * @param data
   */
  setDeliveryLocalization(data: DeliveryData): Observable<StandardPostInterface> {
    return this.deliveryRequest(data, `${ this.rootApi }/setuserlocalization`);
  }

  private deliveryRequest(data: DeliveryData, url: string): Observable<StandardPostInterface> {
    const body = new FormData();
    Object.keys(data).forEach(key => {
      if (Number.isInteger(data[key])) {
        body.append(key, data[key].toString());
      } else {
        body.append(key, data[key]);
      }
    });
    return this.stdRequest(this.http.post<StandardPostInterface>(url, body));
  }

  /**
   * Validate cart
   * @param idCart
   * @param requestCheckoutAmount
   */
  validateCart(idCart: number, requestCheckoutAmount?: number): Observable<ReqValidateCartInterface> {
    const body = new FormData();
    if (requestCheckoutAmount) {
      body.append('request_checkout_amount', requestCheckoutAmount.toString());
    }
    return this.stdRequest(this.http.post<ReqValidateCartInterface>(`${ this.rootApi }/validatecart/${ idCart.toString() }`, body));
  }

  /**
   * Flush cart
   */
  flushCart() {
    this.hasChanges = false;
    this.isSynchronized = false;
    this.syncInProgress = false;
    this.cartState.next(undefined);
    this.isCartReady.next(null);
  }

  /**
   * Get cart summary data API
   * @private
   */
  private getCart(): Observable<CartInterface> {
    return this.stdRequest(this.http.post<ReqCartInterface>(`${ this.rootApi }/cart`, null).pipe(
      map(response => response.cart),
      tap(response => this.syncCartWithServerData(response, true)),
      map(() => this.cartState.getValue()),
      tap(response => {
        // Build isDeliverable
        response?.products?.forEach(item => buildDeliverable(item));

        // TODO: to remove
        response?.products?.forEach(item => {
          item.images?.forEach(image => {
            image.link = image.link.replace('dev.', '');
            image.link = image.link.replace('preprod.', '');
          });
        });
      })
    ));
  }

  /**
   * Get cart by synchronizing data with server
   */
  syncCart(forceSync = false): Observable<CartInterface> {
    if (this.isSynchronized && !forceSync) {
      return of(this.cartState.getValue());
    } else if (this.syncInProgress) {
      return this.cartState.asObservable().pipe(first());
    } else {
      this.syncInProgress = true;
      this.isCartReady.next(false);
      return this.getCart().pipe(
        map(() => this.cartState.getValue()),
        tap(() => {
          this.isSynchronized = true;
          this.hasChanges = false;
          this.isCartReady.next(true);
          setTimeout(() => this.syncInProgress = false, 1000);
        })
      );
    }
  }

  /**
   * Add or update product to cart
   * @param product
   */
  addToCart(product: EStoreProductInterface): Observable<StandardResponseInterface> {
    const localCart = this.cartState.getValue();
    const productIndex = localCart?.products?.findIndex(item => item.id === product.id);
    const cartProduct = productIndex > -1 ? localCart.products[productIndex] : null;
    // Build data
    const data: AddToCartData = {
      id: product.id,
      id_product_attribute: Number.isInteger(product.cartCurrentCombination) ?
        (product.cartCurrentCombination as number) :
        (product.cartCurrentCombination ? (product.cartCurrentCombination as EStoreCombinationInterface).id : 0),
      qty: cartProduct ? cartProduct.cartServerQuantity : product.cartLocalQuantity,
      add: product.cartLocalQuantity > 0 ? 1 : 0,
      del: product.cartLocalQuantity === 0 ? 1 : 0,
      updqty: cartProduct && cartProduct.cartServerQuantity !== product.cartLocalQuantity ? product.cartLocalQuantity : 0
    };
    // Build body from data
    const body = new FormData();
    Object.keys(data).forEach(key => {
      if (typeof data[key] === 'number') {
        body.append(key, (data[key] as number).toString());
      } else {
        body.append(key, data[key]);
      }
    });
    return this.stdRequest<StandardResponseInterface>(this.http.post<StandardResponseInterface>(
      `${ this.rootApi }/addtocart/${ localCart.id_cart.toString() }/${ product.id }`,
      body
    )).pipe(
      tap({
        next: () => {
          product.cartServerQuantity = product.cartLocalQuantity;
          if (productIndex > -1) {
            localCart.products[productIndex] = product;
          } else {
            localCart.nb_products++;
            localCart.products.push(product);
          }
          this.hasChanges = true;
          this.cartState.next(localCart);
        },
        error: () => {
          product.cartLocalQuantity = product.cartServerQuantity || 0;
        }
      }),
      map(response => Object.assign(response, {extra: data.updqty > 0 ? 'updated' : (data.add > 0 ? 'added' : 'removed')}))
    );
  }

  /**
   * Sync products with local cart
   * @param products
   */
  syncProductsWithCart(products: EStoreProductInterface[]) {
    if (!Array.isArray(products)) {
      console.error('syncProductsWithCart: products is not an array');
      return of(null);
    }

    if (!this.isReady()) {
      return this.syncCart().pipe(
        switchMap(() => of(this.syncProductsWithCartWorker(products)))
      );
    } else {
      return of(this.syncProductsWithCartWorker(products));
    }
  }

  /**
   * Worker for Sync products with local cart
   * @param products
   * @private
   */
  private syncProductsWithCartWorker(products: EStoreProductInterface[]): EStoreProductInterface[] {
    products.forEach(product => {
      const cartProduct = this.cartState.getValue()?.products?.find(item => item.id === product.id);

      if (cartProduct) {
        product.cartLocalQuantity = cartProduct.cartLocalQuantity;
        product.cartCurrentCombination = cartProduct.id_product_attribute ? cartProduct.id_product_attribute : 0;

        // Sync product selection
        if (cartProduct.id_product_attribute && product.combinations?.length > 0) {
          const combination = product.combinations.find(item => item.id === cartProduct.id_product_attribute);

          combination?.attributes?.forEach(attr => {
            const group = product.groups?.find(item => item.id === attr.id_group);

            if (group) {
              group.cartChoice = attr.id;
            }
          });
        }
      }
    });
    return products;
  }


  /**
   * Synchronize server data with local data
   * @param serverCart
   * @param replaceLocal
   * @private
   */
  private syncCartWithServerData(serverCart: CartInterface, replaceLocal = false) {
    let localCart = this.cartState.getValue();
    if (localCart && localCart.id_cart === serverCart.id_cart && !replaceLocal) {
      [
        'nb_products', 'total_price', 'total_price_without_tax',
        'total_products', 'total_shipping', 'total_tax', 'date_upd'
      ].forEach(key => {
        localCart[key] = serverCart[key];
      });
    } else {
      localCart = serverCart;
    }
    // Transfer quantity
    localCart?.products?.forEach(item => {
      item.cartLocalQuantity = item.cartQuantity;
      item.cartServerQuantity = item.cartQuantity;
      if (item.id_product_attribute) {
        item.cartCurrentCombination = item.id_product_attribute;
      }
    });
    // Fix issue about nb_products
    localCart.nb_products = localCart.products.length;
    // Fix issue about total_price
    localCart.total_price = localCart.total_shipping + localCart.total_products;
    this.cartState.next(localCart);
  }
}
