import { Injectable } from '@angular/core';
import { Color, DarkColor, generateColor } from '@common/helpers/_colors';
import { appColors } from '@environments/params';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { ConnectionService } from './connection.service';
import { ZoneService } from './zone.service';
import { SchemeInterface, schemes } from '@environments/schemes';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Storage } from '@ionic/storage';
import { Utils } from '@common/helpers/utils';
import { BehaviorSubject } from 'rxjs';
import { NavigationBar } from '@capgo/capacitor-navigation-bar';

export interface ThemeInterface {
  primary: string;
  secondary: string;
  tertiary?: string;
  eStore?: string;
  useDarkMode?: boolean;
  primaryDark?: string;
  secondaryDark?: string;
  tertiaryDark?: string;
}

interface SubdomainTheme {
  name: string;
  faviconPath?: string;
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
    dark: {
      primary: string;
      secondary: string;
      tertiary: string;
    }
  };
  loginColors?: {
    background: string;
    foreground: string;
    primary: string;
    secondary: string;
    tertiary: string;
  };
  apps: {
    ios: string;
    android: string;
    downloadKey?: string;
  } | boolean;
}

export type DarkModeOptions = 'dark' | 'light' | 'auto';

/**
 * Theme service
 * This component manage themes of app with:
 *   - custom theme
 *   - sub-domain theme
 *   - app scheme theme
 */

@Injectable({providedIn: 'root'})
export class ThemeService {
  static DEFAULT_PRIMARY = '#000000';
  static DEFAULT_SECONDARY = '#000000';
  static DEFAULT_PRIMARY_DARK = '#FFFFFF';
  static DEFAULT_SECONDARY_DARK = '#FFFFFF';

  scheme: SchemeInterface;

  private appIcon: HTMLLinkElement = document.querySelector('#appIcon');
  private appTitle: HTMLTitleElement = document.querySelector('#appTitle');

  private currentSubdomainTheme = null;
  private currentTheme: ThemeInterface;
  private currentSubdomain: string;
  // Default theme context (platform excluded)
  private currentDefaultThemeContext: 'none' | 'scheme' | 'subdomain';

  private currentDarkModeState: BehaviorSubject<DarkModeOptions> = new BehaviorSubject<DarkModeOptions>(undefined);
  private isDarkModeState: boolean = false;
  private isInitialized: boolean = false;
  private hasMobileScheme: boolean = false;
  private isLoginEnvironment: boolean = false;
  private hasLoginTheme: boolean = false;

  private mobileBarColor?: string;

  /**
   * Themes for subdomain
   */
  private subdomainThemes: SubdomainTheme[] = [
    {
      name: 'main',
      colors: {
        primary: appColors.light.defaultPrimary,
        secondary: appColors.light.defaultSecondary,
        tertiary: appColors.light.defaultTertiary,
        dark: {
          primary: appColors.dark.defaultPrimary,
          secondary: appColors.dark.defaultSecondary,
          tertiary: appColors.dark.defaultTertiary,
        }
      },
      apps: {
        ios: 'https://apps.apple.com/app/maslo-ex-roadoo/id1013823680',
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.roadoonetwork',
        downloadKey: 'security.download-app.download-mobile.default'
      }
    },
    {
      name: 'sbmoffshore',
      faviconPath: '/assets/icons/fav/sbmoffshore.png',
      colors: {
        primary: '#65666b',
        secondary: '#76777b',
        tertiary: '#76777b',
        dark: {
          primary: '#93949a',
          secondary: '#85868a',
          tertiary: '#757679',
        }
      },
      apps: false
    },
    {
      name: 'mylink',
      faviconPath: '/assets/icons/fav/mylink.png',
      colors: {
        primary: '#4b4a4a',
        secondary: '#D8750F',
        tertiary: '#D8750F',
        dark: {
          primary: '#b6b4b4',
          secondary: '#f08c28',
          tertiary: '#f08c28',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/app/mylink-by-newrest/id1547803200',
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.mylinknewrest',
        downloadKey: 'security.download-app.download-mobile.default'
      }
    },
    {
      name: 'nexity',
      faviconPath: '/assets/icons/fav/nexity.png',
      colors: {
        primary: '#d22238',
        secondary: '#9B192A',
        tertiary: '#9B192A',
        dark: {
          primary: '#dd2c41',
          secondary: '#e66577',
          tertiary: '#e66577',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/app/les-experts-by-nexity-roadoo/id1301366611',
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.nexity',
        downloadKey: 'security.download-app.download-mobile.default'
      }
    },
    {
      name: 'pistonr',
      faviconPath: '/assets/icons/fav/pistonr.png',
      colors: {
        primary: '#8c52ff',
        secondary: '#000000',
        tertiary: '#512e94',
        dark: {
          primary: '#8c52ff',
          secondary: '#000000',
          tertiary: '#512e94',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/app/pistonr/id1620543201',
        android: 'https://play.google.com/store/apps/details?id=com.roadoo.pistonr',
        downloadKey: 'security.download-app.download-mobile.pistonr'
      }
    },
    {
      name: 'abrisud',
      faviconPath: '/assets/icons/fav/abrisud.png',
      colors: {
        primary: '#E3A413',
        secondary: '#000E28',
        tertiary: '#91690d',
        dark: {
          primary: '#E3A413',
          secondary: '#000E28',
          tertiary: '#91690d',
        }
      },
      apps: {
        ios: 'https://apps.apple.com/app/abrisud-teams/id6444213747',
        android: 'https://play.google.com/store/apps/details?id=com.abrisud.abrisudteams'
      }
    },
    {
      name: 'cigaverte',
      faviconPath: '/assets/icons/fav/cigaverte.png',
      colors: {
        primary: '#ACC915',
        secondary: '#818282',
        tertiary: '#818282',
        dark: {
          primary: '#ACC915',
          secondary: '#818282',
          tertiary: '#818282',
        }
      },
      apps: false
    },
    {
      name: 'mideaplus',
      faviconPath: '/assets/icons/fav/mideaplus.png',
      colors: {
        primary: '#1F94D2',
        secondary: '#000000',
        tertiary: '#000000',
        dark: {
          primary: '#2ea2e0',
          secondary: '#FFFFFF',
          tertiary: '#FFFFFF',
        }
      },
      loginColors: {
        background: '#1F94D2',
        foreground: '#FFFFFF',
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        tertiary: '#FFFFFF'
      },
      apps: {
        ios: 'https://apps.apple.com/app/midea-plus-fideliza-y-gana/id6593685333',
        android: 'https://play.google.com/store/apps/details?id=app.maslo.mideaplus'
      }
    }
  ];

  /**
   * Check color and return newColor if differ from compColor
   * @param newColor
   * @param compColor
   */
  static uniqColor(newColor: string, compColor: string): string {
    return newColor?.replace('#', '') !== compColor?.replace('#', '') ? newColor : null;
  }

  /**
   * Color mixer
   * @param color1 color 1
   * @param color2 color 2
   * @param ratio ratio 0 to 1
   */
  colorMixer = (color1: string, color2: string, ratio: number) => {
    const hex = (x: number) => {
      const hexVal = x.toString(16);
      return hexVal.length === 1 ? '0' + hexVal : hexVal;
    };
    const r = Math.ceil(parseInt(color1.substring(1, 3), 16) * ratio + parseInt(color2.substring(1, 3), 16) * (1 - ratio));
    const g = Math.ceil(parseInt(color1.substring(3, 5), 16) * ratio + parseInt(color2.substring(3, 5), 16) * (1 - ratio));
    const b = Math.ceil(parseInt(color1.substring(5, 7), 16) * ratio + parseInt(color2.substring(5, 7), 16) * (1 - ratio));
    return '#' + hex(r) + hex(g) + hex(b);
  };

  constructor(private materialCssVarsService: MaterialCssVarsService,
              private connectionServ: ConnectionService,
              private zoneServ: ZoneService,
              private storage: Storage,
              private ionicPlatform: Platform) {
  }

  /**
   * Main entry to set-up theme of App
   * @param subdomain subdomain if provided
   */
  setupTheme(subdomain?: string) {
    const defaultTheme: ThemeInterface = {
      primary: appColors?.light.defaultPrimary,
      secondary: appColors?.light.defaultSecondary,
      primaryDark: appColors?.dark.defaultPrimary,
      secondaryDark: appColors?.dark.defaultSecondary
    };
    this.currentDefaultThemeContext = 'none';

    // App Scheme theme
    if (this.hasMobileScheme) {
      const schemeTheme = this.getCurrentScheme();
      defaultTheme.primary = schemeTheme.colors.primary;
      defaultTheme.secondary = schemeTheme.colors.secondary;
      defaultTheme.primaryDark = schemeTheme.colors.primaryDark;
      defaultTheme.secondaryDark = schemeTheme.colors.secondaryDark;
      this.currentDefaultThemeContext = 'scheme';
    }

    // Subdomain theme
    if (subdomain) {
      const subdomainTheme = this.getSubdomainTheme(subdomain);
      if (subdomainTheme) {
        defaultTheme.primary = subdomainTheme.colors.primary;
        defaultTheme.secondary = subdomainTheme.colors.secondary;
        defaultTheme.primaryDark = subdomainTheme.colors.dark.primary;
        defaultTheme.secondaryDark = subdomainTheme.colors.dark.secondary;
        this.currentDefaultThemeContext = 'subdomain';

        this.appIcon.href = subdomainTheme.faviconPath;
        this.appTitle.textContent = Utils.capitalizeFirst(subdomainTheme.name);

        this.currentSubdomainTheme = subdomain;
      }
      this.currentSubdomain = subdomain;
    }

    // Platform theme (and override default)
    const theme = this.determineAndGetPlatformTheme(defaultTheme);

    this.currentTheme = theme;

    // Dark Mode
    theme.useDarkMode = this.isDarkMode();

    // Apply new theme
    const isCustomTheme =
      theme?.primary !== ThemeService.DEFAULT_PRIMARY ||
      theme?.secondary !== ThemeService.DEFAULT_SECONDARY;
    this.applyThemeToApp(theme, isCustomTheme);

    this.isInitialized = true;
  }

  /**
   * Get default theme excepted platform (applied only to legacy app, scheme and subdomain theme)
   */
  getDefaultTheme(): ThemeInterface {
    switch (this.currentDefaultThemeContext) {
      case 'scheme':
        const schemeTheme = this.getCurrentScheme();
        return {
          primary: schemeTheme.colors.primary,
          secondary: schemeTheme.colors.secondary,
          primaryDark: schemeTheme.colors.primaryDark,
          secondaryDark: schemeTheme.colors.secondaryDark,
          useDarkMode: this.isDarkModeState
        };
      case 'subdomain':
        const subdomainTheme = this.getSubdomainTheme(this.currentSubdomain);
        if (subdomainTheme) {
          return {
            primary: subdomainTheme.colors.primary,
            secondary: subdomainTheme.colors.secondary,
            primaryDark: subdomainTheme.colors.dark.primary,
            secondaryDark: subdomainTheme.colors.dark.secondary,
            useDarkMode: this.isDarkModeState
          };
        }
    }
    return {
      primary: ThemeService.DEFAULT_PRIMARY,
      secondary: ThemeService.DEFAULT_SECONDARY,
      primaryDark: ThemeService.DEFAULT_PRIMARY_DARK,
      secondaryDark: ThemeService.DEFAULT_SECONDARY_DARK,
      useDarkMode: this.isDarkModeState
    };
  }

  /**
   * Get current theme
   */
  getCurrentTheme(): ThemeInterface {
    return this.currentTheme;
  }

  /**
   * Apply new theme
   * @param theme
   */
  applyNewTheme(theme: ThemeInterface) {
    // Dark Color
    if (!theme.primaryDark && !!theme.primary) {
      theme.primaryDark = (new DarkColor(theme.primary)).hex;
    }
    if (!theme.secondaryDark && !!theme.secondary) {
      theme.secondaryDark = (new DarkColor(theme.secondary)).hex;
    }

    // Dark Mode
    theme.useDarkMode = this.isDarkMode();

    // Apply new theme
    const isCustomTheme =
      theme?.primary !== ThemeService.DEFAULT_PRIMARY ||
      theme?.secondary !== ThemeService.DEFAULT_SECONDARY;
    this.applyThemeToApp(theme, isCustomTheme);
  }

  /**
   * Define or determine dark mode
   * @param newMode
   */
  async determineOrDefineDarkMode(newMode?: DarkModeOptions) {
    let modeToApply: DarkModeOptions;
    if (!newMode) {
      modeToApply = (await this.storage.get('__dark-theme') as DarkModeOptions) || 'auto';
    } else {
      modeToApply = newMode;
      await this.storage.set('__dark-theme', modeToApply);
    }
    this.isDarkModeState = modeToApply === 'dark' ||
      (modeToApply !== 'light' && window.matchMedia('(prefers-color-scheme: dark)').matches);

    if (this.isInitialized) {
      this.setupTheme(this.currentSubdomain);
    }

    this.currentDarkModeState.next(modeToApply);
  }

  /**
   * Return current dark mode config
   */
  async getDarkModeConfig() {
    if (this.currentDarkModeState.getValue() === undefined) {
      await this.determineOrDefineDarkMode();
    }
    return this.currentDarkModeState.getValue();
  }

  /**
   * Get dark mode state
   */
  getDarkModeState() {
    return this.currentDarkModeState.asObservable();
  }

  /**
   * Indicate if is dark mode
   */
  isDarkMode() {
    return this.isDarkModeState;
  }

  /**
   * Define app scheme
   * @param appId app Id
   */
  defineScheme(appId: string) {
    this.scheme = schemes.default;
    if (typeof appId === 'string') {
      schemes.customs?.forEach(item => {
        if (item.appIds?.indexOf(appId.toLowerCase()) > -1) {
          this.scheme = item;
          this.hasMobileScheme = true;
        }
      });
    }

    this.zoneServ.setScheme(this.scheme);

    return this.scheme;
  }

  /**
   * Get current scheme defined
   */
  getCurrentScheme() {
    return this.scheme || schemes.default;
  }

  getCurrentSchemeIfMobile() {
    if (this.ionicPlatform.is('capacitor')) {
      return this.getCurrentScheme();
    }
    return null;
  }

  /**
   * Get app links for different theme
   */
  getCurrentAppLinks(): { android: string | boolean, ios: string | boolean } | boolean {
    const themeName = this.currentSubdomainTheme || 'main';
    return this.getSubdomainTheme(themeName)?.apps || false;
  }

  /**
   * Apply login theme
   * Function shall be call when theme shall be applied
   */
  applyLoginTheme() {
    const theme: ThemeInterface = Object.assign(this.currentTheme);
    let curSubdomain = this.currentSubdomain;
    if (this.hasMobileScheme) {
      curSubdomain = this.getCurrentScheme().landing;
    }

    const subdomainTheme = this.getSubdomainTheme(curSubdomain);
    if (subdomainTheme?.loginColors) {
      theme.primary = subdomainTheme.loginColors.primary;
      theme.secondary = subdomainTheme.loginColors.secondary;
      theme.tertiary = subdomainTheme.loginColors.tertiary;
      theme.useDarkMode = false;

      this.mobileBarColor = subdomainTheme.loginColors.background;

      this.applyColor('background', subdomainTheme.loginColors.background);
      this.applyColor('foreground', subdomainTheme.loginColors.foreground);

      this.applyColor('medium', this.colorMixer(subdomainTheme.loginColors.background, subdomainTheme.loginColors.foreground, .5));
      this.applyColor('dark', this.colorMixer(subdomainTheme.loginColors.background, subdomainTheme.loginColors.foreground, .1));
      this.applyColor('light', this.colorMixer(subdomainTheme.loginColors.background, subdomainTheme.loginColors.foreground, .9));

      this.applyThemeToApp(theme, true);

      this.hasLoginTheme = true;
    } else {
      this.removeLoginTheme();
    }
  }

  /**
   * Remove Login theme
   */
  removeLoginTheme() {
    if (this.hasLoginTheme) {
      ['background', 'foreground', 'medium', 'dark', 'light'].forEach(val => this.removeColor(val));

      this.mobileBarColor = undefined;

      this.applyNewTheme(this.currentTheme);

      this.hasLoginTheme = false;
    }
  }

  setLoginEnvironment(result: boolean) {
    this.isLoginEnvironment = result;
  }

  isLoginEnvironmentActive() {
    return this.isLoginEnvironment;
  }

  /**
   * Apply style to status bar according dark theme
   */
  private applyStatusBarStyle(isDarkMode = false) {
    if (this.ionicPlatform.is('capacitor')) {
      StatusBar.setStyle({style: isDarkMode ? Style.Dark : Style.Light}).then(/* Nothing to do */);

      if (this.ionicPlatform.is('android')) {
        const color = this.mobileBarColor ? this.mobileBarColor : (isDarkMode ? '#121212' : '#FFFFFF');
        StatusBar.setBackgroundColor({color}).then(/* Nothing to do */);
        NavigationBar.setNavigationBarColor({color}).then(/* Nothing to do */);
      }
    }
  }

  /**
   * Get subdomain theme by name in list
   * @param name theme name
   */
  private getSubdomainTheme(name: string) {
    return this.subdomainThemes.find(themeInList => themeInList.name === name) || null;
  }

  /**
   * Apply new theme to app
   * @param theme
   * @param isCustomTheme
   * @private
   */
  private applyThemeToApp(theme: ThemeInterface, isCustomTheme = true) {
    // Determine Light colors
    let primary = theme?.primary || ThemeService.DEFAULT_PRIMARY;
    let secondary = theme?.primary || ThemeService.DEFAULT_SECONDARY;
    let tertiary = theme?.tertiary || null;
    let eStore = theme?.eStore || null;

    if (!tertiary) {
      const color = new Color(theme.primary);
      tertiary = color.shade(.3).hex;
    }

    if (!eStore) {
      const color = new Color(theme.primary);
      eStore = color.shade(.9).hex;
    }

    // Update Light JS Color
    appColors.light.primary = primary;
    appColors.light.secondary = secondary;
    appColors.light.tertiary = tertiary;
    appColors.light.eStore = eStore;

    // Dark Mode management - determine colors
    if (theme.useDarkMode) {
      primary = theme?.primaryDark || (primary ? (new DarkColor(primary).hex) : ThemeService.DEFAULT_PRIMARY_DARK);
      secondary = theme?.secondaryDark || (secondary ? (new DarkColor(secondary).hex) : ThemeService.DEFAULT_SECONDARY_DARK);
      tertiary = theme?.tertiaryDark || (tertiary ? (new DarkColor(tertiary).hex) : null);

      if (!tertiary) {
        const color = new DarkColor(theme.primary);
        tertiary = color.tint(.3).hex;
      }

      // Update Dark JS Color
      appColors.dark.primary = primary;
      appColors.dark.secondary = secondary;
      appColors.dark.tertiary = tertiary;
    }

    // Update Ionic colors
    if (isCustomTheme) {
      this.applyColor('primary', primary);
      this.applyColor('secondary', secondary);
      this.applyColor('tertiary', tertiary);
      this.applyColor('e-store', eStore);
    }
    this.applyStatusBarStyle(theme.useDarkMode);

    // Update Material Colors
    this.materialCssVarsService.setDarkTheme(theme.useDarkMode);
    if (isCustomTheme) {
      this.materialCssVarsService.setPrimaryColor(primary);
      this.materialCssVarsService.setAccentColor(secondary);
    }

    // Update used color
    appColors.used = theme.useDarkMode ? appColors.dark : appColors.light;

    // If not custom theme, reove to apply default theme
    if (!isCustomTheme) {
      this.removeTheme();
    }
  }

  /**
   * Remove current theme and apply default
   */
  private removeTheme() {
    // Update CSS
    this.removeColor('primary');
    this.removeColor('secondary');
    this.removeColor('tertiary');
    this.removeColor('e-store');

    // Update JS
    ['light', 'dark'].forEach(key => {
      appColors[key].primary = appColors[key].defaultPrimary;
      appColors[key].secondary = appColors[key].defaultSecondary;
      appColors[key].tertiary = appColors[key].defaultTertiary;
      if (key === 'light') {
        const color = new Color(appColors[key].primary);
        appColors[key].eStore = color.shade(.73).hex;
      }
    });

    // Update Material
    // TODO: Propose a PR with a function to remove variables
    ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900', 'A100', 'A200', 'A400', 'A700'].forEach(key => {
      document.documentElement.style.removeProperty(`--palette-primary-${ key }`);
      document.documentElement.style.removeProperty(`--palette-primary-contrast-${ key }`);
      document.documentElement.style.removeProperty(`--palette-accent-${ key }`);
      document.documentElement.style.removeProperty(`--palette-accent-contrast-${ key }`);
    });
    // this.materialCssVarsService.setPrimaryColor(params.colors.defaultPrimary);
    // this.materialCssVarsService.setAccentColor(params.colors.defaultSecondary);
  }

  /**
   * Apply color and determine shade
   * @param property property name
   * @param colorValue color value
   * @param useDark use dark mode statut to determine color
   */
  protected applyColor(property: string, colorValue: string, useDark = false) {
    const color = generateColor(property, colorValue, useDark);
    document.body.style.setProperty(`--ion-color-${ property }`, color.value);
    document.body.style.setProperty(`--ion-color-${ property }-rgb`, color.valueRgb);
    document.body.style.setProperty(`--ion-color-${ property }-shade`, color.shade);
    document.body.style.setProperty(`--ion-color-${ property }-tint`, color.tint);
    document.body.style.setProperty(`--ion-color-${ property }-contrast`, color.contrast);
    document.body.style.setProperty(`--ion-color-${ property }-contrast-rgb`, color.contrastRgb);
  }

  /**
   * Remove ion-color from current app
   * @param property property name
   */
  protected removeColor(property: string) {
    document.body.style.removeProperty(`--ion-color-${ property }`);
    document.body.style.removeProperty(`--ion-color-${ property }-rgb`);
    document.body.style.removeProperty(`--ion-color-${ property }-shade`);
    document.body.style.removeProperty(`--ion-color-${ property }-tint`);
    document.body.style.removeProperty(`--ion-color-${ property }-contrast`);
    document.body.style.removeProperty(`--ion-color-${ property }-contrast-rgb`);
  }

  /**
   * Return the platform theme
   * @private
   */
  private determineAndGetPlatformTheme(defaultTheme?: ThemeInterface): ThemeInterface {
    const platform = this.connectionServ.getSelectedPlatformValue();

    // If no color defined by platform, return default theme
    if (!platform?.primary_color && !platform?.secondary_color) {
      return defaultTheme;
    }

    // If at least one color defined by platform
    if (!platform?.primary_color || !platform?.secondary_color) {
      return {
        primary: platform?.primary_color || ThemeService.DEFAULT_PRIMARY,
        secondary: platform?.secondary_color || ThemeService.DEFAULT_SECONDARY,
        primaryDark: (!!platform?.primary_color ? (new DarkColor(platform?.primary_color)).hex : ThemeService.DEFAULT_PRIMARY_DARK),
        secondaryDark: (!!platform?.secondary_color ? (new DarkColor(platform?.secondary_color)).hex : ThemeService.DEFAULT_SECONDARY_DARK),
      };
    }

    // If both colors provided, return platform color
    return {
      primary: platform?.primary_color,
      secondary: platform?.secondary_color,
      primaryDark: (new DarkColor(platform?.primary_color)).hex,
      secondaryDark: (new DarkColor(platform?.secondary_color)).hex,
    };
  }

}
