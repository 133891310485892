import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class StateService {

  private inDashboardState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userCoinsState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private userCashState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private userGiftsState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private toRefresh: string;

  /**
   * Get in dashboard state
   */
  isInDashboardState() {
    return this.inDashboardState;
  }

  /**
   * Get in dashboard value
   */
  isInDashboard() {
    return this.inDashboardState.getValue();
  }

  /**
   * Set in dashboard value
   * @info this callback is called in interceptor when field in_dashboard is provided in response of request
   * @param value new value
   */
  setInDashboardValue(value: boolean) {
    if (this.inDashboardState.getValue() !== value) {
      this.inDashboardState.next(value);
    }
  }

  /**
   * Get user coins state
   */
  getUserCoinsState() {
    return this.userCoinsState;
  }

  /**
   * Get user coins value
   */
  getUserCoinsValue() {
    return this.userCoinsState.getValue();
  }

  /**
   * Set user coins value
   * @info this callback is called in interceptor when field user_coins is provided in response of request
   * @param value new value
   */
  setUserCoinsValue(value: number) {
    if (this.userCoinsState.getValue() !== value) {
      this.userCoinsState.next(value);
    }
  }

  /**
   * Get user cash state
   */
  getUserCashState() {
    return this.userCashState;
  }

  /**
   * Get user cash value
   */
  getUserCashValue() {
    return this.userCashState.getValue();
  }

  /**
   * Set user cash value
   * @info this callback is called in interceptor when field user_cash is provided in response of request
   * @param value new value
   */
  setUserCashValue(value: number) {
    if (this.userCashState.getValue() !== value) {
      this.userCashState.next(value);
    }
  }

  /**
   * Get user gifts state
   */
  getUserGiftsState() {
    return this.userGiftsState;
  }

  /**
   * Get user gifts value
   */
  getUserGiftsValue() {
    return this.userGiftsState.getValue();
  }

  /**
   * Set user gifts value
   * @info this callback is called in interceptor when field user_gifts is provided in response of request
   * @param value new value
   */
  setUserGiftsValue(value: number) {
    if (this.userGiftsState.getValue() !== value) {
      this.userGiftsState.next(value);
    }
  }

  /**
   * Set a flag to refresh in ionViewWillEnter of page
   * @param flag flag key
   */
  refreshAdd(flag: string) {
    this.toRefresh += flag + '+';
  }

  /**
   * Remove a flag
   * @param flag flag key
   */
  refreshRemove(flag: string) {
    this.toRefresh = this.toRefresh.replace(flag + '+', '');
  }

  /**
   * If refresh flag raised ?
   * @param flag flag key
   */
  refreshFlag(flag: string) {
    return this.toRefresh?.indexOf(flag + '+') > -1;
  }
}
