import { EStoreProductInterface } from '@common/interfaces/api/client';
import * as moment from 'moment/moment';
import { TranslocoService } from '@ngneat/transloco';
import { Utils } from '@common/helpers/utils';


/**
 * Build useful data for product
 * @param product server product item
 */
export const buildProductData = (product: EStoreProductInterface) => {

  product.priceCustomer = product.final_price;
  // Public price or final price + reduction amount
  product.priceSelling = product.public_price > 0 && product.public_price > product.final_price ? product.public_price : product.final_price + product.reduction_amount;
  product.serverQuantity = product.quantity;
  product.quantityToAdd = 1;

  // Determine quantity to display in message
  if (product.quantity === 999) {
    // If promotion date is set, ends after current date and expires in 3 days, show message last chance
    if (product.reduction_from !== product.reduction_to &&
      moment(product.reduction_to).isAfter() &&
      moment(product.reduction_to).subtract(3, 'days').isBefore()) {
      product.quantityNbMessage = 1;
    }
  } else {
    product.quantityNbMessage = product.quantity;
  }

  // Determine if product has discount
  product.hasDiscount = (product.public_price > 0 && product.public_price > product.final_price) || (
    (product.reduction_amount > 0) &&
    (product.reduction_from === product.reduction_to || moment(product.reduction_to).isAfter())
  );

  // Default combination
  product.groups?.forEach(group => {
    if (group.cartChoice === undefined) {
      group.cartChoice = group.default;
    }
  });

  // Is Travel product ?
  product.isTravel = product.features?.filter(f => f.name === 'Voyage').pop()?.value === 'Oui';
  product.isExternalTravel = product.isTravel && (product.features?.filter(f => f.name === 'URL').pop()?.value?.length || 0) > 0;
  product.isWorldiaPartner = product.isTravel && (product.features.filter(feature => feature.name === 'URL' && feature.value?.indexOf('worldia') > -1).length > 0);

  // TODO: To remove when prod is ready
  // Replace dev link to admin link
  if (!product.isTravel) {
    product.images?.forEach(item => item.url = item.url?.replace('https://dev.admin.', 'https://admin.')?.replace('https://preprod.admin.', 'https://admin.') || '');
  }

  // Default image url
  const nbImg = product?.images?.length || 0;
  if (nbImg > 0) {
    const image = nbImg > 1 && product.isTravel ? product.images[Utils.randomInt(0, nbImg)] : product.images[0];
    product.defaultImageUrl = image.url;
    product.defaultImageLegend = image.legend;
  }

  // Determine deliverable in return result
  return buildDeliverable(product);
};

/**
 * Build deliverable data for product
 * @param product server product item
 */
export const buildDeliverable = (product: EStoreProductInterface) => {
  product.isDeliverableFr = product.delivery?.map(val => val.toUpperCase()).indexOf('FR') > -1 && product.delivery?.length === 1;
  product.isDeliverableEu = product.delivery?.length > 0 && !product.isDeliverableFr;

  return product;
};


/**
 * Check if one product is deliverable
 * @param products products list
 */
export const isDeliverable = (products: EStoreProductInterface[]): boolean => {
  return !products?.find(item => !item.is_deliverable);
};


/**
 * Build cart error message key
 * @param err cart service error
 * @param product product data
 * @param translateServ translation service
 */
export const buildCartErrorMessageKey = (err: any, product: EStoreProductInterface, translateServ: TranslocoService) => {
  const errObj = typeof err?.error === 'object' ? err.error : err;

  // TODO: fix issue and clean integration
  return errObj?.message === 'No enough stock available for the selected quantity' ?
    translateServ.translate(
      product?.bb_product_id > 0 && product?.cart_quantity === 0 ?
        'e-store.text.victim-of-success-long' :
        'e-store.text.stock-unavailable'
    ) :
    translateServ.translate('alerts.std-error') + '<br />' + errObj?.message;
};
