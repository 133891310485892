import { HttpBaseService } from '../http-base.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { StandardResponseInterface } from '@common/interfaces/api';
import {
  AdminRewardsBadgeData, AdminRewardsGiftData, AdminUserCashData, AdminUserCoinsData, AdminUsersFilterData,
  ReqAdminCashDetailsInterface, ReqAdminCashInterface, ReqAdminCoinsInterface, ReqAdminRewardsBadgeInterface,
  ReqAdminRewardsBadgesInterface, ReqAdminRewardsGiftsDistributionInterface, ReqAdminRewardsGiftInterface,
  ReqAdminUserCashInterface, ReqAdminUserCoinsInterface, ReqAdminUsersInterface, AdminUserGiftData, ReqAdminRewardsGiftsHistoryInterface
} from '@common/interfaces/api/admin';
import { PaginatorInterface } from '@common/interfaces/common.interface';
import { RewardsBadgeInterface, ReqRewardsGiftsInterface, RewardsGiftInterface } from '@common/interfaces/api/client';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RewardsService } from '@common/services/api/client';
import { ConnectionService } from '@common/services/connection.service';


@Injectable({providedIn: 'root'})
export class AdminRewardsService extends HttpBaseService {

  badgesList: RewardsBadgeInterface[] = [];
  giftsList: RewardsGiftInterface[] = [];

  idPlatform: number;

  constructor(protected http: HttpClient,
              protected connectionServ: ConnectionService,
              protected rewardsServ: RewardsService) {
    super(http);
  }

  /*************************
   ********* Coins ********
   *************************/

  /**
   * Get coins from administration
   * @param data data options
   * @param paginator paginator options
   */
  coins(data?: AdminUsersFilterData, paginator?: PaginatorInterface): Observable<ReqAdminUsersInterface> {
    const body = new FormData();

    if (data?.search) {
      body.append('search', data.search);
    }
    if (data?.disabled) {
      body.append('disabled', data.disabled ? '1' : '0');
    }

    if (data?.getCsv) {
      body.append('get_csv', data.getCsv ? '1' : '0');
    } else {

      let page = paginator?.page || 1;
      let max = paginator?.max || 30;
      page = page < 1 ? 1 : page;
      max = max < 1 ? 1 : max;
      const start = (page - 1) * max;
      body.append('start', start.toString());
      body.append('limit', max.toString());
    }

    return this.stdRequest(this.http.post<ReqAdminUsersInterface>(`${ this.rootApi }/administration/Coins`, body));
  }

  /**
   * Used to get users coins history
   * @param users users Id
   * @param getCsv get CSV ?
   */
  coinsHistory(users?: Array<string>, getCsv: boolean = false): Observable<ReqAdminCoinsInterface> {
    const body = new FormData();
    if (users) {
      body.append('users', JSON.stringify(users));
    }
    if (getCsv) {
      body.append('get_csv', 'true');
    }
    return this.stdRequest(this.http.post<ReqAdminCoinsInterface>(`${ this.rootApi }/administration/Coins/history`, body));
  }

  /**
   * Get CSV file to update coins
   * @param search search string
   */
  getCoinsCsv(search?: string): Observable<ReqAdminUserCoinsInterface> {
    const body = new FormData();
    body.append('get_csv', 'true');
    if (search) {
      body.append('search', search);
    }
    return this.stdRequest(this.http.post<ReqAdminUserCoinsInterface>(`${ this.rootApi }/administration/Coins/update`, body));
  }

  /**
   * Update users coins
   * @param data user object
   */
  updateCoins(data: AdminUserCoinsData[]): Observable<ReqAdminUserCoinsInterface> {
    const body = new FormData();
    body.append('users', JSON.stringify(data));
    return this.stdRequest(this.http.post<ReqAdminUserCoinsInterface>(`${ this.rootApi }/administration/Coins/update`, body));
  }

  /**
   * Update Coins with CSV file
   * @param csvFile csvFile
   */
  updateCoinsWithCsv(csvFile: File): Observable<ReqAdminUserCoinsInterface> {
    const body = new FormData();
    body.append('csvFile', csvFile);
    return this.stdRequest(this.http.post<ReqAdminUserCoinsInterface>(`${ this.rootApi }/administration/Coins/update`, body));
  }

  /*************************
   ********* Cash ********
   *************************/

  /**
   * Get cash from administration
   * @param data data options
   * @param paginator paginator options
   */
  cash(data?: AdminUsersFilterData, paginator?: PaginatorInterface): Observable<ReqAdminCashInterface> {
    const body = new FormData();

    if (data?.search) {
      body.append('search', data.search);
    }
    if (data?.disabled) {
      body.append('disabled', data.disabled ? '1' : '0');
    }
    if (data?.date_from) {
      body.append('date_from', data.date_from);
    }
    if (data?.date_to) {
      body.append('date_to', data.date_to);
    }

    if (data?.getCsv) {
      body.append('get_export', 'true');
    } else {

      let page = paginator?.page || 1;
      let max = paginator?.max || 30;
      page = page < 1 ? 1 : page;
      max = max < 1 ? 1 : max;
      const start = (page - 1) * max;
      body.append('debut', start.toString());
      body.append('limit', max.toString());
    }

    return this.stdRequest(this.http.post<ReqAdminCashInterface>(`${ this.rootApi }/administration/CashWallet`, body));
  }

  /**
   * Used to get users cash details
   * @param idUser id of user
   * @param dateFrom date from
   * @param dateTo date to
   */
  cashDetails(idUser, dateFrom?: string, dateTo?: string): Observable<ReqAdminCashDetailsInterface> {
    const body = new FormData();
    if (dateFrom) {
      body.append('date_from', dateFrom);
    }
    if (dateTo) {
      body.append('date_to', dateTo);
    }
    return this.stdRequest(this.http.post<ReqAdminCashDetailsInterface>(`${ this.rootApi }/administration/CashWallet/${ idUser.toString() }`, body));
  }

  /**
   * Get CSV file to update cash
   * @param search search string
   * @param date month date as YYY-MM-DD
   */
  getCashCsv(search?: string, date?: string): Observable<ReqAdminUserCashInterface> {
    const body = new FormData();
    body.append('get_csv', 'true');
    if (search) {
      body.append('search', search);
    }
    if (date) {
      body.append('date', date);
    }
    return this.stdRequest(this.http.post<ReqAdminUserCashInterface>(`${ this.rootApi }/administration/CashWallet/update`, body));
  }

  /**
   * Update users cash
   * @param data user object
   * @param date month date as YYY-MM-DD
   */
  updateCash(data: AdminUserCashData[], date?: string): Observable<ReqAdminUserCashInterface> {
    const body = new FormData();
    body.append('users', JSON.stringify(data));
    if (date) {
      body.append('date', date);
    }
    return this.stdRequest(this.http.post<ReqAdminUserCashInterface>(`${ this.rootApi }/administration/CashWallet/update`, body));
  }

  /**
   * Update Cash with CSV file
   * @param csvFile csvFile
   * @param date month date as YYY-MM-DD
   */
  updateCashWithCsv(csvFile: File, date?: string): Observable<ReqAdminUserCashInterface> {
    const body = new FormData();
    body.append('csvFile', csvFile);
    if (date) {
      body.append('date', date);
    }
    return this.stdRequest(this.http.post<ReqAdminUserCashInterface>(`${ this.rootApi }/administration/CashWallet/update`, body));
  }

  /*************************
   ********* Badges ********
   *************************/

  /**
   * Used to get the list of all platform badges.
   */
  badges(reload = false): Observable<ReqAdminRewardsBadgesInterface> {
    if (this.badgesList?.length > 0 && !reload && this.idPlatform === this.connectionServ.getSelectedPlatformValue()?.id_action) {
      return of({badges: this.badgesList, error: false});
    } else {
      return this.stdRequest<ReqAdminRewardsBadgesInterface>(
        this.http.post<ReqAdminRewardsBadgesInterface>(`${ this.rootApi }/administration/Badges`, null)
      ).pipe(tap(res => {
        this.badgesList = res.badges;
        this.idPlatform = this.connectionServ.getSelectedPlatformValue()?.id_action;
      }));
    }
  }

  /**
   * Used to get a badge.
   */
  badge(idBadge: number): Observable<RewardsBadgeInterface> {
    return new Observable<RewardsBadgeInterface>(observer => {
      // Load badge function
      const loadBadge = () => {
        const badge = this.badgesList?.find(item => item.id_badge === idBadge);
        if (badge) {
          observer.next(badge);
          observer.complete();
        } else {
          this.stdRequest<ReqAdminRewardsBadgeInterface>(
            this.http.post<ReqAdminRewardsBadgeInterface>(`${ this.rootApi }/administration/Badges/${ idBadge.toString() }`, null)
          ).subscribe({
            next: res => {
              this.badgesList.push(res.badge);
              observer.next(res.badge);
              observer.complete();
            },
            error: err => observer.error(err)
          });
        }
      };

      if (!(this.badgesList?.length > 0)) {
        this.badges().subscribe({
          next: () => loadBadge(),
          error: err => observer.error(err)
        });
      } else {
        loadBadge();
      }
    });
  }

  /**
   * Used to create a new badge.
   * @param data data object
   * @param picture picture to add
   * @param progressEvent event to report progress
   */
  addBadge(data: AdminRewardsBadgeData,
           picture?: File,
           progressEvent?: BehaviorSubject<number>): Observable<ReqAdminRewardsBadgeInterface> {
    return this.addEditBadge('add', data, picture, progressEvent);
  }

  /**
   * Used to update a new badge.
   * @param idBadge id of badge
   * @param data data object
   * @param picture picture to add
   * @param progressEvent event to report progress
   */
  updateBadge(idBadge: number,
              data: AdminRewardsBadgeData,
              picture?: File,
              progressEvent?: BehaviorSubject<number>): Observable<ReqAdminRewardsBadgeInterface> {
    return this.addEditBadge('update', data, picture, progressEvent, idBadge);
  }

  /**
   * Used to create a new badge or edit.
   * @param type add or update
   * @param data data object
   * @param picture picture to add
   * @param progressEvent event to report progress
   * @param idBadge id of badge
   */
  addEditBadge(type: 'add' | 'update',
               data: AdminRewardsBadgeData,
               picture?: File,
               progressEvent?: BehaviorSubject<number>,
               idBadge?: number): Observable<ReqAdminRewardsBadgeInterface> {
    const body = new FormData();
    if (idBadge && type === 'update') {
      data.id_badge = idBadge.toString();
    }
    body.append('badge', JSON.stringify(data));
    if (picture) {
      body.append('image', picture);
    }
    return this.uploadRequest<ReqAdminRewardsBadgeInterface>(`${ this.rootApi }/administration/Badges/${ type }`, body, progressEvent).pipe(tap(response => {
      const idx = this.badgesList?.findIndex(item => item.id_badge === idBadge);
      if (response?.badge) {
        if (idx > -1) {
          this.badgesList[idx] = response?.badge;
        } else {
          this.badgesList.push(response?.badge);
        }
      } else {
        this.badgesList = [];
      }
      this.rewardsServ.badgesResetList();
    }));
  }


  /**
   * Used to delete a badge.
   * @param idBadge id of survey
   */
  deleteBadge(idBadge: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_badge', idBadge.toString());
    return this.stdRequest<StandardResponseInterface>(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Badges/delete`, body)).pipe(tap(() => {
      const index = this.badgesList.findIndex(item => item.id_badge === idBadge);
      if (index !== -1) {
        this.badgesList.splice(index, 1);
      }
    }));
  }

  /*************************
   ********* Gifts ********
   *************************/

  /**
   * Used to get the list of all platform gifts.
   */
  gifts(reload = false): Observable<ReqRewardsGiftsInterface> {
    if (this.giftsList?.length > 0 && !reload && this.idPlatform === this.connectionServ.getSelectedPlatformValue()?.id_action) {
      return of({gifts: this.giftsList, error: false});
    } else {
      return this.stdRequest<ReqRewardsGiftsInterface>(
        this.http.post<ReqRewardsGiftsInterface>(`${ this.rootApi }/administration/Gifts`, null)
      ).pipe(tap(res => {
        this.giftsList = res.gifts;
        this.idPlatform = this.connectionServ.getSelectedPlatformValue()?.id_action;
      }));
    }
  }

  /**
   * Used to get a gift.
   */
  gift(idGift: number): Observable<RewardsGiftInterface> {
    if (!idGift) {
      return of(null);
    }
    return new Observable<RewardsGiftInterface>(observer => {
      // Load gift function
      const loadGift = () => {
        const gift = this.giftsList?.find(item => item?.id_gift === idGift);
        if (gift) {
          observer.next(gift);
          observer.complete();
        } else {
          this.stdRequest<ReqAdminRewardsGiftInterface>(
            this.http.post<ReqAdminRewardsGiftInterface>(`${ this.rootApi }/administration/Gifts/${ idGift.toString() }`, null)
          ).subscribe({
            next: res => {
              if (res.gift) {
                this.giftsList.push(res.gift);
              }
              observer.next(res.gift);
              observer.complete();
            },
            error: err => observer.error(err)
          });
        }
      };

      if (!(this.giftsList?.length > 0)) {
        this.gifts().subscribe({
          next: () => loadGift(),
          error: err => observer.error(err)
        });
      } else {
        loadGift();
      }
    });
  }

  /**
   * Get gifts history
   * @param data filter data
   * @param paginator paginator
   */
  giftsDistribution(data?: AdminUsersFilterData, paginator?: PaginatorInterface): Observable<ReqAdminRewardsGiftsDistributionInterface> {
    const body = new FormData();

    if (data?.search) {
      body.append('search', data.search);
    }
    if (data?.date_from) {
      body.append('date_from', data.date_from);
    }
    if (data?.date_to) {
      body.append('date_to', data.date_to);
    }

    if (data?.getCsv) {
      body.append('get_export', 'true');
    } else {

      let page = paginator?.page || 1;
      let max = paginator?.max || 30;
      page = page < 1 ? 1 : page;
      max = max < 1 ? 1 : max;
      const start = (page - 1) * max;
      body.append('debut', start.toString());
      body.append('limit', max.toString());
    }

    return this.stdRequest<ReqAdminRewardsGiftsDistributionInterface>(
      this.http.post<ReqAdminRewardsGiftsDistributionInterface>(`${ this.rootApi }/administration/Gifts/distribution`, body)
    );
  }

  /**
   * Update users cash
   * @param data user object
   */
  giftsDistributionUpdate(data: AdminUserGiftData[]): Observable<ReqAdminUserCashInterface> {
    const body = new FormData();
    body.append('users', JSON.stringify(data));
    return this.stdRequest(this.http.post<ReqAdminUserCashInterface>(`${ this.rootApi }/administration/Gifts/distribution/update`, body));
  }

  /**
   * Used to get users gifts history
   * @param user user Id
   */
  giftsHistory(user?: number): Observable<ReqAdminRewardsGiftsHistoryInterface> {
    const body = new FormData();
    if (user > 0) {
      body.append('id_customer', user.toString());
    }
    return this.stdRequest(this.http.post<ReqAdminRewardsGiftsHistoryInterface>(`${ this.rootApi }/administration/Gifts/distribution/history`, body));
  }

  /**
   * Used to create a new gift.
   * @param data data object
   * @param pictures[] picture to add
   * @param progressEvent event to report progress
   */
  addGift(data: AdminRewardsGiftData,
          pictures?: File[],
          progressEvent?: BehaviorSubject<number>): Observable<ReqAdminRewardsGiftInterface> {
    return this.addEditGift('add', data, pictures, progressEvent);
  }

  /**
   * Used to update a new gift.
   * @param idGift id of gift
   * @param data data object
   * @param pictures[] picture to add
   * @param progressEvent event to report progress
   */
  updateGift(idGift: number,
             data: AdminRewardsGiftData,
             pictures?: File[],
             progressEvent?: BehaviorSubject<number>): Observable<ReqAdminRewardsGiftInterface> {
    return this.addEditGift('update', data, pictures, progressEvent, idGift);
  }

  /**
   * Used to create a new gift or edit.
   * @param type add or update
   * @param data data object
   * @param pictures[] picture to add
   * @param progressEvent event to report progress
   * @param idGift id of gift
   */
  addEditGift(type: 'add' | 'update',
              data: AdminRewardsGiftData,
              pictures?: File[],
              progressEvent?: BehaviorSubject<number>,
              idGift?: number): Observable<ReqAdminRewardsGiftInterface> {
    const body = new FormData();
    if (idGift && type === 'update') {
      data.id_gift = idGift.toString();
    }
    body.append('gift', JSON.stringify(data));
    pictures?.forEach((picture, index) => {
      if (picture) {
        body.append('image[' + index.toString() + ']', picture);
      }
    });
    return this.uploadRequest<ReqAdminRewardsGiftInterface>(`${ this.rootApi }/administration/Gifts/${ type }`, body, progressEvent).pipe(tap(response => {
      const idx = this.giftsList?.findIndex(item => item.id_gift === idGift);
      if (response?.gift) {
        if (idx > -1) {
          this.giftsList[idx] = response?.gift;
        } else {
          this.giftsList.push(response?.gift);
        }
      } else {
        this.giftsList = [];
      }
      this.rewardsServ.giftsResetList();
    }));
  }


  /**
   * Used to delete a gift.
   * @param idGift id of survey
   */
  deleteGift(idGift: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_gift', idGift.toString());
    return this.stdRequest<StandardResponseInterface>(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gifts/delete`, body)).pipe(tap(() => {
      const index = this.giftsList.findIndex(item => item.id_gift === idGift);
      if (index !== -1) {
        this.giftsList.splice(index, 1);
        this.rewardsServ.giftsResetList();
      }
    }));
  }

  /**
   * Used to give a gift.
   * @param idGift id of gift
   * @param idUser id of customer
   */
  giveGift(idGift: number, idUser: number): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('id_customer', idUser.toString());
    return this.stdRequest<StandardResponseInterface>(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gifts/${ idGift.toString() }/give`, body));
  }

  /**
   * Used to ask for sourcing a gift.
   * @param idGift id of gift
   */
  askForSourcingGift(idGift: number): Observable<StandardResponseInterface> {
    return this.stdRequest<StandardResponseInterface>(this.http.post<StandardResponseInterface>(`${ this.rootApi }/administration/Gifts/${ idGift.toString() }/ask`, null));
  }

}
