import { Injectable } from '@angular/core';
import { HttpBaseService } from '@common/services/api/http-base.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  AddToCartData,
  CartInterface,
  DeliveryData, EStoreCombinationInterface, EStoreProductInterface, ReqCartInterface,
  ReqCountriesInterface,
  ReqValidateCartInterface,
  StandardPostInterface
} from '@common/interfaces/api/client';
import { filter, first, map, tap } from 'rxjs/operators';
import { buildProductData } from '@app/e-store/e-store.utils';


/**
 * Get the id of product attribute
 * @param product
 */
export const getIdProductAttribute = (product: EStoreProductInterface): number => {
  if (product.id_product_attribute > 0) {
    return product.id_product_attribute;
  }
  return Number.isInteger(product.cartCurrentCombination) ?
    (product.cartCurrentCombination as number) :
    (product.cartCurrentCombination ? (product.cartCurrentCombination as EStoreCombinationInterface).id : 0);
};


@Injectable({providedIn: 'root'})
export class CartService extends HttpBaseService {

  private cartState: BehaviorSubject<CartInterface | null | undefined> = new BehaviorSubject<CartInterface>(undefined);
  private cartNbState: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /**
   * Get the number of products
   */
  getNbProducts(): Observable<number> {
    if (this.cartState.getValue() === undefined) {
      this.getCart().subscribe();
    }
    return this.cartNbState.asObservable();
  }

  /**
   * Wait for cart to be ready
   */
  whenReady() {
    return this.cartState.asObservable().pipe(
      first(cart => cart !== undefined && cart !== null),
      map(cart => cart !== undefined && cart !== null)
    );
  }

  /**
   * Indicate if cart is ready
   */
  isReady() {
    return !!this.cartState.getValue();
  }

  /**
   * Flush cart
   */
  flushCart() {
    this.cartNbState.next(0);
    this.cartState.next(undefined);
  }

  /********
   * APIs
   /**
   * Get countries
   */
  countries(): Observable<ReqCountriesInterface> {
    return this.stdRequest(this.http.post<ReqCountriesInterface>(`${ this.rootApi }/getcountries`, null));
  }

  /**
   * Set the delivery address
   * @param data
   */
  setDeliveryAddress(data: DeliveryData): Observable<StandardPostInterface> {
    return this.deliveryRequest(data, `${ this.rootApi }/setdeliveryaddress`);
  }

  /**
   * Get the delivery localization
   * @param data
   */
  setDeliveryLocalization(data: DeliveryData): Observable<StandardPostInterface> {
    return this.deliveryRequest(data, `${ this.rootApi }/setuserlocalization`);
  }

  /**
   * Validate cart
   * @param requestCheckoutAmount
   */
  validateCart(requestCheckoutAmount?: number): Observable<ReqValidateCartInterface> {
    const body = new FormData();
    if (requestCheckoutAmount) {
      body.append('request_checkout_amount', requestCheckoutAmount.toString());
    }
    return this.stdRequest(this.http.post<ReqValidateCartInterface>(`${ this.rootApi }/store/cart/validate`, body));
  }

  /**
   * Add product to cart
   * @param product
   * @param quantity
   */
  addToCart(product: EStoreProductInterface, quantity: number): Observable<StandardPostInterface> {
    return new Observable<StandardPostInterface>(observer => {
      this.getCart().subscribe(cart => {
        const cartProduct = cart.products.find(item => item.id_product === product.id_product);
        const del = (quantity !== 0) && ((cartProduct?.cart_quantity || 0) + quantity === 0) ? 1 : 0;
        const updqty = (cartProduct?.cart_quantity || 0) + quantity;
        const qty = cartProduct?.cart_quantity || 1;

        // Build data
        const data: AddToCartData = {
          id: product.id_product,
          id_product_attribute: getIdProductAttribute(product),
          qty,
          add: (qty !== updqty || cartProduct?.cart_quantity === undefined) && !del ? 1 : 0,
          del,
          updqty
        };

        // Build body from data
        const body = new FormData();
        Object.keys(data).forEach(key => {
          if (typeof data[key] === 'number') {
            body.append(key, (data[key] as number).toString());
          } else {
            body.append(key, data[key]);
          }
        });

        this.stdRequest<StandardPostInterface>(this.http.post<StandardPostInterface>(
          `${ this.rootApi }/store/cart/add/${ product.id_product.toString() }`,
          body
        )).subscribe({
          next: response => {

            response.extra = data.updqty > 0 ? 'updated' : (data.add > 0 ? 'added' : 'removed');

            // Update cart
            this.getCart(true).subscribe({
              next: () => {
                observer.next(response);
                observer.complete();
              },
              error: () => {
                observer.next(response);
                observer.complete();
              }
            });
          },
          error: err => observer.error(err)
        });
      });
    });
  }

  /**
   * Get synchronized cart
   * @param forceSync force synchronization
   */
  getSyncCart(forceSync: boolean = false): Observable<CartInterface> {
    if (this.cartState.getValue() === undefined || forceSync) {
      this.getCart(forceSync).subscribe();
    }
    return this.cartState.asObservable();
  }

  /**
   * Get cart summary data API
   */
  getCart(forceSync: boolean = false): Observable<CartInterface> {
    if (this.cartState.getValue() !== undefined && !forceSync) {
      return this.cartState.asObservable().pipe(first());
    } else {
      this.cartState.next(null);
      return this.stdRequest(this.http.post<ReqCartInterface>(`${ this.rootApi }/store/cart`, null).pipe(
        map(response => response.cart),
        tap(response => {
          // Build isDeliverable
          response?.products?.forEach(item => buildProductData(item));
        }),
        tap(cart => {
          this.cartState.next(cart);
          this.cartNbState.next(cart?.products?.length || 0);
        })
      ));
    }
  }

  /**
   * Has delivery localization ?
   */
  hasDeliveryLocalization(): Observable<boolean> {
    const checkDeliveryData = (data) => {
      return (data?.delivery?.postcode?.length || 0) > 0;
    };
    if (!this.isReady()) {
      return this.whenReady().pipe(
        filter(value => value === true),
        map(() => checkDeliveryData(this.cartState.getValue()))
      );
    }
    return of(checkDeliveryData(this.cartState.getValue()));
  }

  /**
   * Get the delivery options
   * @param data data of delivery
   * @param url endpoint
   * @private
   */
  private deliveryRequest(data: DeliveryData, url: string): Observable<StandardPostInterface> {
    const body = new FormData();
    Object.keys(data).forEach(key => {
      if (Number.isInteger(data[key])) {
        body.append(key, data[key].toString());
      } else {
        body.append(key, data[key]);
      }
    });
    return this.stdRequest(this.http.post<StandardPostInterface>(url, body));
  }

  /**
   * Sync local cart with server cart
   * Note: this function can be removed in case of immutable object
   * @param serverCart
   * @private
   */
  private syncLocalCart(serverCart: CartInterface): Observable<CartInterface> {
    return new Observable(observer => {
      let localCart = this.cartState.getValue();
      if (localCart) {
        // Merge local cart with server cart
        serverCart.products.forEach(serverProduct => {
          const localProduct = localCart.products.find(item =>
            item.id_product === serverProduct.id_product &&
            item.id_product_attribute === serverProduct.id_product_attribute
          );
          if (localProduct) {
            localProduct.cart_quantity = serverProduct.cart_quantity;
          } else {
            localCart.products.push(serverProduct);
          }
        });
        localCart.total_price = serverCart.total_price;
        localCart.total_price_without_tax = serverCart.total_price_without_tax;
        localCart.total_products = serverCart.total_products;
        localCart.total_shipping = serverCart.total_shipping;
        localCart.total_tax = serverCart.total_tax;
      } else {
        localCart = serverCart;
      }
      observer.next(localCart);
      observer.complete();
    });
  }

}

