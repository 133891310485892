import { Component, Input, OnInit } from '@angular/core';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { UrlTree } from '@angular/router';

@Component({
  selector: 'app-back-button',
  template: `
    <ion-button [fill]="fill"
                [color]="color"
                (click)="back()" *ngIf="canGoBack">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </ion-button>
    <ion-button [fill]="fill"
                [color]="color"
                [routerLink]="default || '/'" routerDirection="back"
                *ngIf="!canGoBack">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </ion-button>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `
})
export class BackButtonComponent implements OnInit {

  @Input() fill: string;
  @Input() color: string;

  @Input() default: string | UrlTree | any[];

  canGoBack: boolean = false;

  constructor(private navCtrl: NavController,
              private routerOutlet: IonRouterOutlet) {
  }

  ngOnInit() {
    this.canGoBack = this.routerOutlet.canGoBack();
  }

  back() {
    if (this.routerOutlet.canGoBack()) {
      this.navCtrl.back();
    }
  }

}
