import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent, ngxEditorLocals } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { ThemeService } from '@common/services/theme.service';
import { ConnectionInterceptor } from '@common/helpers/connection.interceptor';
import { ErrorsInterceptor } from '@common/helpers/errors.interceptor';
import { GeneralService } from '@common/services/api';
import { NewsFeedService } from '@common/services/api/client';
import { ConnectionService } from '@common/services/connection.service';
import { IonicStorageModule } from '@ionic/storage';
import { TranslocoService } from '@ngneat/transloco';
import { FormErrorsService } from '@common/services/form-errors.service';
import { ToolboxService } from '@common/services/toolbox.service';
import { CommonService } from '@common/services/common.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { TrackingClientService } from '@common/services/client';
import { SideMenuModule } from '@common/components/app/side-menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CguAgreementModule } from '@common/components/modals/cgu-agreement';
import { TeamService } from '@common/services/team.service';
import { PlatformModule } from '@angular/cdk/platform';
import { AccessControlService } from '@common/services/access-control';
import { CircleProgressModule } from '@common/components/utils/circle-progress';
import { NgxEditorModule } from 'ngx-editor';
import { AppHeaderModule } from '@common/components/app/header';
import { ResponsiveService } from '@common/services/responsive.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCustom } from '@common/helpers/_material/mat-paginator-intl-custom';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxMaskModule } from 'ngx-mask';
import { StateService } from '@common/services/state.service';
import { MaterialCssVarsModule } from 'angular-material-css-vars';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { CalendarModule, DateAdapter as CalendarDateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CameraService } from '@common/services/camera.service';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { IntercomModule } from 'ng-intercom';
import { environment } from '@environments/environment';
import { NotificationsService } from '@common/services/notifications';
import { OffersModule } from '@common/components/modals/offers';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoadingModule } from '@common/components/utils/loading';
import { SheetModalController } from '@common/services/sheet-modal.controller';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MaintenanceInterceptor } from '@common/helpers/maintenance.interceptor';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material/datepicker';
import { ProfileFieldsModule } from '@common/components/app/profile-fields';
import { DashboardConsistencyFieldsModule } from '@app/dashboard/_consistency-fields';
import { TreeModule } from '@common/components/utils/tree';
import { DashboardSideMenuModule } from '@app/dashboard/_side-menu/side-menu.module';

import * as moment from 'moment';

import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/pt';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/it';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    // Transition:
    // - https://github.com/ionic-team/ionic-framework/blob/master/core/src/utils/transition/md.transition.ts
    // - https://www.damirscorner.com/blog/posts/20200501-CustomizingPageTransitionsInIonic5.html
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true
    }),
    IonicStorageModule.forRoot({
      name: '_roadoo',
      driverOrder: ['localstorage', 'indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    OAuthModule.forRoot(),
    SideMenuModule,
    DashboardSideMenuModule,
    BrowserAnimationsModule,
    CguAgreementModule,
    PlatformModule,
    LoadingModule,
    CircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 10,
      innerStrokeWidth: 10,
      outerStrokeColor: '#000000',
      innerStrokeColor: '#A7A9AB',
      animationDuration: 2000,
      space: -10,
      maxPercent: 10000,
      outerStrokeLinecap: 'square',
      animateTitle: false,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      showBackground: false,
      responsive: true,
      startFromZero: true,
      lazy: false,
      renderOnClick: false,
      showZeroOuterStroke: false
    }),
    NgxEditorModule.forRoot({locals: ngxEditorLocals}),
    AppHeaderModule,
    GoogleChartsModule.forRoot({version: '49'}),
    NgxMaskModule.forRoot(),
    MaterialCssVarsModule.forRoot(),
    CalendarModule.forRoot({provide: CalendarDateAdapter, useFactory: momentAdapterFactory}),
    IntercomModule.forRoot({
      appId: environment.intercomAppId,
      updateOnRouterChange: true,
      vertical_padding: 20,
      horizontal_padding: 20,
    }),
    MaterialCssVarsModule.forRoot({
      darkThemeClass: 'dark-theme',
      lightThemeClass: 'light-theme'
    }),
    OffersModule,
    MatAutocompleteModule,
    ColorPickerModule.forRoot(),
    ProfileFieldsModule,
    DashboardConsistencyFieldsModule,
    TreeModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConnectionInterceptor,
      multi: true
    },
    {provide: LOCALE_ID, deps: [TranslocoService], useFactory: (serv: any) => serv.getDefaultLang()},
    TranslocoService,
    CommonService,
    ThemeService,
    GeneralService,
    TrackingClientService,
    ConnectionService,
    MediaCapture,
    Camera,
    FormErrorsService,
    ToolboxService,
    NewsFeedService,
    TeamService,
    AccessControlService,
    ResponsiveService,
    StateService,
    NotificationsService,
    CameraService,
    SheetModalController,
    // MAT_DATE_MODULE
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom},
    {provide: MAT_DATE_LOCALE, useValue: 'en-EN'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {}
    },
    {provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]},
    {provide: MAT_DATEPICKER_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
