import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { ConnectionService } from '@common/services/connection.service';
import { Subscription } from 'rxjs';
import { UserProfileInterface } from '@common/interfaces/common.interface';

@Component({
  selector: 'app-header-side-menu-button',
  templateUrl: './side-menu-button.component.html',
  styleUrls: ['./side-menu-button.component.scss'],
})
export class HeaderSideMenuButtonComponent implements OnInit, OnDestroy, OnChanges {

  @Input() menuId?: string;

  @Input() isDashboard: boolean = false;

  profile?: UserProfileInterface;

  menuIdToApply: string = 'sideMenu';

  private subscriptions: Subscription[] = [];

  constructor(private menuCtrl: MenuController,
              private connectionServ: ConnectionService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.connectionServ.getToken().subscribe(token => {
      if (token) {
        this.profile = token.user;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDashboard) {
      this.menuIdToApply = changes.isDashboard.currentValue ? 'dashboardMenu' : this.menuId || 'sideMenu';
    }
  }

  @HostListener('click')
  toggleMenu() {
    this.menuCtrl.toggle(this.menuIdToApply).then(/* Nothing to do */);
  }

}
