import { HttpBaseService } from '@common/services/api/http-base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  EStoreBrandsData,
  EStoreCategoryInterface,
  EStoreProductsData, ReqEStoreBrandsInterface,
  ReqEstoreCategoriesInterface, ReqEStoreFavoritesInterface, ReqEStoreOrderDetailsInterface,
  ReqEStoreOrdersInterface,
  ReqEStoreProductInterface,
  ReqEStoreProductsInterface, ReqEStoreSearchInterface,
  ReqEStoreStoreInterface
} from '@common/interfaces/api/client';
import { Utils } from '@common/helpers/utils';
import { buildProductData } from '@app/e-store/e-store.utils';
import { map, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class EStoreService extends HttpBaseService {

  static NUMBER_OF_PRODUCTS = 30;
  static NUMBER_OF_BRANDS = 70;

  private lastProducts: {
    result?: ReqEStoreProductsInterface,
    start: number,
    limit: number
  };

  private lastBrands: {
    result?: ReqEStoreBrandsInterface,
    start: number,
    limit: number
  };

  // Thematics
  // undefined: not loaded
  // null: loading
  // []: loaded
  private thematicsState: BehaviorSubject<EStoreCategoryInterface[] | null | undefined> = new BehaviorSubject<EStoreCategoryInterface[] | null | undefined>(undefined);

  constructor(protected http: HttpClient) {
    super(http);

    this.lastProducts = {start: 0, limit: EStoreService.NUMBER_OF_PRODUCTS};
  }

  /**
   * Get store data
   * @param idCategory id of category
   */
  store(idCategory: number = 0): Observable<ReqEStoreStoreInterface> {
    if (idCategory === 0) {
      this.thematicsState.next(null);
    }
    return this.stdRequest<ReqEStoreStoreInterface>(
      this.http.post<ReqEStoreStoreInterface>(`${ this.rootApi }/store` + (idCategory > 0 ? `/${ idCategory.toString() }` : ''), undefined)
    ).pipe(
      tap(response => {
        // Categories
        response?.categories?.forEach(category => {
          category.products?.forEach((item, idx) => category.products[idx] = buildProductData(item));
        });
        // Top Products
        response?.top_products?.forEach((item, idx) => response.top_products[idx] = buildProductData(item));
        // Thematics
        if (idCategory === 0) {
          this.thematicsState.next(response.categories);
        }
      })
    );
  }

  /**
   * Get categories
   * @param idCategory id of category
   */
  categories(idCategory: number = 0): Observable<ReqEstoreCategoriesInterface> {
    if (idCategory === 0) {
      this.thematicsState.next(null);
    }
    const url = idCategory > 0 ? `${ this.rootApi }/store/${ idCategory.toString() }/categories` : `${ this.rootApi }/store/categories`;
    return this.stdRequest<ReqEstoreCategoriesInterface>(this.http.post<ReqEstoreCategoriesInterface>(url, undefined)).pipe(
      tap(response => {
        // Thematics
        if (idCategory === 0) {
          this.thematicsState.next(response.categories);
        }
      })
    );
  }

  /**
   * Get thematics
   */
  thematics(): Observable<EStoreCategoryInterface[]> {
    if (this.thematicsState.value === undefined) {
      this.categories().subscribe();
    }
    return this.thematicsState.asObservable();
  }

  /**
   * Get brands
   * @param data filters
   * @param next get next result
   */
  brands(data?: EStoreBrandsData, next = false): Observable<ReqEStoreBrandsInterface> {
    // Init
    if (next === false) {
      this.lastBrands = {result: null, start: 0, limit: EStoreService.NUMBER_OF_BRANDS};
    } else {
      this.lastBrands.start += EStoreService.NUMBER_OF_BRANDS;

      if (this.lastBrands.start > Utils.toNumber(this.lastBrands.result?.count)) {
        this.lastBrands.result.isCompleted = true;
        return of(this.lastBrands.result);
      }
    }

    const body = new FormData();
    body.append('start', this.lastBrands.start.toString());
    body.append('limit', this.lastBrands.limit.toString());

    if (data) {
      Object.keys(data).forEach(key => {
        if (data[key]) {
          if (key === 'categories') {
            body.append(key, JSON.stringify(data[key]));
          } else {
            body.append(key, data[key]);
          }
        }
      });
    }

    return this.stdRequest(this.http.post<ReqEStoreBrandsInterface>(`${ this.rootApi }/store/brands`, body).pipe(
      // TODO: to remove
      tap(response => {
        response?.brands?.forEach(item => {
          item.img_url = item.img_url.replace('dev.', '');
          item.img_url = item.img_url.replace('preprod.', '');
        });
      }),
      // Update local brands
      tap(response => {
        if (next) {
          // Update if next
          this.lastBrands.result.brands = this.lastBrands.result.brands.concat(response.brands);
          if ((response.brands?.length || 0) === 0) {
            this.lastBrands.result.isCompleted = true;
          }
        } else {
          this.lastBrands.result = response;
          this.lastBrands.result.isCompleted = false;
        }
      }),
      map(() => this.lastBrands.result)
    ));
  }


  /**
   * Get products
   * @param idCategory id of category
   * @param data data for filtering
   * @param next next page
   */
  products(idCategory: number = 0, data?: EStoreProductsData, next: boolean = false): Observable<ReqEStoreProductsInterface> {
    // Init
    if (next === false) {
      this.lastProducts = {result: null, start: 0, limit: EStoreService.NUMBER_OF_PRODUCTS};
    } else {
      this.lastProducts.start += EStoreService.NUMBER_OF_PRODUCTS;

      if (this.lastProducts.start > Utils.toNumber(this.lastProducts.result?.products_count)) {
        this.lastProducts.result.isCompleted = true;
        return of(this.lastProducts.result);
      }
    }

    const body = new FormData();
    body.append('start', this.lastProducts.start.toString());
    body.append('limit', this.lastProducts.limit.toString());
    if (data) {
      Object.keys(data).forEach(key => {
        if (key === 'search') {
          if (data[key]) {
            body.append(key, JSON.stringify({string: data[key]}));
          }
        } else if (key === 'orderby') {
          if (data[key] === 'bestSellers') {
            body.append('orderby', 'totalQuantitySold');
          } else {
            body.append('orderby', data[key]);
          }
        } else {
          if (data[key]) {
            body.append(key, data[key]);
          }
        }
      });
    }
    const url = idCategory > 0 ? `${ this.rootApi }/store/${ idCategory.toString() }/products` : `${ this.rootApi }/store/products`;
    return this.stdRequest<ReqEStoreProductsInterface>(
      this.http.post<ReqEStoreProductsInterface>(url, body)
    ).pipe(
      tap(response => response?.products.forEach((item, idx) => {
        response.products[idx] = buildProductData(item);

        // TODO: to remove
        // response.brands?.forEach(brand => brand.img_url = brand.img_url.replace('dev.', '').replace('preprod.', ''));
      })),
      tap(response => {
        if (next) {
          // Update if next
          this.lastProducts.result.products = this.lastProducts.result.products.concat(response.products);
          if ((response.products?.length || 0) === 0) {
            this.lastProducts.result.isCompleted = true;
          }
        } else {
          this.lastProducts.result = response;
          this.lastProducts.result.isCompleted = false;
        }
      }),
      map(() => this.lastProducts.result)
    );
  }

  /**
   * Get product
   * @param idProduct id of product
   */
  product(idProduct: number): Observable<ReqEStoreProductInterface> {
    return this.stdRequest<ReqEStoreProductInterface>(
      this.http.post<ReqEStoreProductInterface>(`${ this.rootApi }/store/product/${ idProduct.toString() }`, undefined)
    ).pipe(tap(response => {
      // Product
      response.product = buildProductData(response.product);

      // Top products
      response.top_products?.forEach((item, idx) => response.top_products[idx] = buildProductData(item));
    }));
  }

  /**
   * Get suggestions elements
   * @param search expression to search
   * @param idCategory id category
   */
  search(search: string, idCategory: number = 0): Observable<ReqEStoreSearchInterface> {
    const body = new FormData();
    body.append('search', search);
    const url = idCategory > 0 ? `${ this.rootApi }/store/search/${ idCategory.toString() }` : `${ this.rootApi }/store/search`;
    return this.stdRequest<ReqEStoreSearchInterface>(this.http.post(url, body)).pipe(
      tap(response => {
        // TODO: To remove
        response.products?.forEach((item, idx) => response.products[idx].image_url = item.image_url.replace('dev.', '').replace('preprod.', ''));
      })
    );
  }

  /**
   * Get favorites
   */
  favorites() {
    return this.stdRequest<ReqEStoreFavoritesInterface>(
      this.http.post<ReqEStoreFavoritesInterface>(`${ this.rootApi }/store/favorites`, undefined)
    ).pipe(tap(response => {
      // Products
      response.products?.forEach((item, idx) => response.products[idx] = buildProductData(item));
    }));
  }

  /**
   * Add to favorites
   */
  addToFavorites(idProduct: number, idAttribute: number = 0) {
    const body = new FormData();
    if (idAttribute > 0) {
      body.append('id_product_attribute', idAttribute.toString());
    }
    return this.stdRequest<ReqEStoreFavoritesInterface>(
      this.http.post<ReqEStoreFavoritesInterface>(`${ this.rootApi }/store/favorites/add/${ idProduct.toString() }`, body)
    );
  }

  /**
   * Remove from favorites
   */
  removeFromFavorites(idProduct: number) {
    return this.stdRequest<ReqEStoreFavoritesInterface>(
      this.http.post<ReqEStoreFavoritesInterface>(`${ this.rootApi }/store/favorites/delete/${ idProduct.toString() }`, undefined)
    );
  }

  /**
   * Get orders
   */
  orders(): Observable<ReqEStoreOrdersInterface> {
    return this.stdRequest<ReqEStoreOrdersInterface>(
      this.http.post<ReqEStoreOrdersInterface>(`${ this.rootApi }/orders`, undefined)
    ).pipe(
      tap(response => {
        // TODO: To remove
        response.orders?.forEach(order => {
          order.products?.forEach((item, idx) => order.products[idx].image_url = item.image_url.replace('dev.', '').replace('preprod.', ''));
        });
      })
    );
  }

  /**
   * Get order details
   */
  order(idOrder: number): Observable<ReqEStoreOrderDetailsInterface> {
    return this.stdRequest<ReqEStoreOrderDetailsInterface>(
      this.http.post<ReqEStoreOrderDetailsInterface>(`${ this.rootApi }/order/${ idOrder.toString() }`, undefined)
    );
  }

  /**
   * Used to download invoice
   * @param idOrder id of order
   * @param name name of order
   */
  downloadInvoice(idOrder: number, name?: string) {
    this.http.get(`${ this.rootApi }/order/${ idOrder.toString() }/invoice`, {responseType: 'blob'}).subscribe(response => {
      const href = URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = href;
      link.download = name ? `${ name }.pdf` : `invoice-${ idOrder }.pdf`;

      link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      }));
    });
  }

}
