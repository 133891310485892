import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { PlatformSelectModule } from '@common/components/app/platform-select';
import { DashboardSideMenuComponent } from './side-menu.component';
import { CopyrightModule } from '@common/components/app/copyright/copyright.module';


@NgModule({
  declarations: [
    DashboardSideMenuComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    RouterModule,
    PlatformSelectModule,
    CopyrightModule
  ],
  exports: [
    DashboardSideMenuComponent
  ]
})
export class DashboardSideMenuModule {
}
