import { Injectable } from '@angular/core';
import { HttpBaseService } from '@common/services/api/http-base.service';
import { Observable, switchMap } from 'rxjs';
import {
  PlatformReloadCheckoutOptionsProps,
  ReqCheckoutInterface
} from '@common/interfaces/api/client';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { appColors } from '@environments/params';
import { NavController } from '@ionic/angular';

import { Browser } from '@maslo/browser';
import { tap } from 'rxjs/operators';
import { ToolboxService } from '@common/services/toolbox.service';
import { CheckoutStatusContextType } from '@app/checkout-status';
import { ZoneService } from '@common/services/zone.service';
import { StandardResponseInterface } from '@common/interfaces/api';


@Injectable()
export class CheckoutService extends HttpBaseService {

  constructor(protected http: HttpClient,
              protected navCtrl: NavController,
              protected zoneServ: ZoneService,
              protected toolboxServ: ToolboxService) {
    super(http);
  }

  /**
   * Process the reload platform checkout to get Stripe Payement Intent
   * @param idPlatform id of platform
   * @param options options for checkout
   */
  reloadPlatformCheckout(idPlatform: number, options: PlatformReloadCheckoutOptionsProps): Observable<boolean> {
    const body = new FormData();
    const urls = this.getCheckoutUrls('reload-by-card', options.slackTs);
    body.append('success_url', urls.successUrl);
    body.append('cancel_url', urls.cancelUrl);
    if (options.amount) {
      body.append('amount', options.amount.toString());
    }
    if (options.checkAmount) {
      body.append('check_amount', options.checkAmount.toString());
    }
    if (options.idInvoice) {
      body.append('id_invoice', options.idInvoice.toString());
    }

    return this.http.post<ReqCheckoutInterface>(environment.checkoutRoot + `/${ idPlatform.toString() }/checkout`, body).pipe(
      switchMap((checkoutResponse) => this.processToStripeCheckout(checkoutResponse.checkout_url)),
      tap({
        error: err => {
          this.toolboxServ.flash({
            message: this.toolboxServ.translate('alerts.std-error') + '<br />' + (err?.error?.message || err?.message),
            type: ToolboxService.TYPE_ERROR,
            duration: 5000
          }).then(/* Nothing to do */);
        }
      })
    );
  }

  /**
   * Process the reload order checkout to get Stripe Payement Intent
   * @param idOrder id of order
   */
  orderCheckout(idOrder: number): Observable<boolean> {
    const body = new FormData();
    const urls = this.getCheckoutUrls('payment-by-card');
    body.append('success_url', urls.successUrl);
    body.append('cancel_url', urls.cancelUrl);
    return this.http.post<ReqCheckoutInterface>(environment.checkoutRoot + `/order/${ idOrder.toString() }/checkout`, body).pipe(
      switchMap((checkoutResponse) => this.processToStripeCheckout(checkoutResponse.checkout_url)),
      tap({
        error: err => {
          this.toolboxServ.flash({
            message: this.toolboxServ.translate('alerts.std-error') + '<br />' + (err?.error?.message || err?.message),
            type: ToolboxService.TYPE_ERROR,
            duration: 5000
          }).then(/* Nothing to do */);
        }
      })
    );
  }

  /**
   * Process the cancel order and refund credits
   * @param idOrder id of order
   */
  orderRefund(idOrder: number): Observable<StandardResponseInterface> {
    return this.http.post<StandardResponseInterface>(environment.checkoutRoot + `/order/${ idOrder.toString() }/refund`, undefined);
  }

  /**
   * Process to checkout through Stripe service
   * @param checkoutUrl url of Stripe checkout
   */
  private processToStripeCheckout(checkoutUrl: string): Observable<boolean> {
    return new Observable((observer) => {
      Browser.open({
        url: checkoutUrl,
        view: 'default',
        presentationStyle: 'popover',
        toolbarColor: appColors.used.background,
        windowName: '_self'
      }).then(/* Nothing to do */);

      Browser.removeAllListeners().then(() => {

        Browser.addListener('browserClosed', () => {

          observer.next(true);
          observer.complete();

        }).then(/* Nothing to do */);
      });
    });
  }

  private getCheckoutUrls(context: CheckoutStatusContextType = 'payment-by-card', ts?: string) {
    const uri = this.zoneServ.getContextualUrl();

    return {
      successUrl: uri + '/checkout-status?checkoutStatus=confirmed&context=' + context + (ts ? '&ts=' + ts : ''),
      cancelUrl: uri + '/checkout-status?checkoutStatus=canceled&context=' + context + (ts ? '&ts=' + ts : ''),
    };
  }
}
