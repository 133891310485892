/**
 * Highlight text inside text by inserting strong tag
 */


import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({name: 'highlight'})
export class HighlightPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(value: string, toHighlight?: string): string | SafeHtml {
    if (!toHighlight) {
      return value;
    }

    const splitValue = (toHighlight || '').split(' ');
    let hlStr = '';
    splitValue.forEach((str, index) => {
      if (index === splitValue.length - 1) {
        hlStr += str;
      } else {
        hlStr += str + `( [^${ splitValue[index + 1] }]+)`;
      }
    });

    const highlightedValue = value?.replace(new RegExp(hlStr, 'gi'), match => `<strong>${ match }</strong>`) || '';

    return this.sanitizer.bypassSecurityTrustHtml(highlightedValue);
  }
}
