import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConnectionService } from '@common/services/connection.service';
import { Subscription } from 'rxjs';
import { UserProfileInterface } from '@common/interfaces/common.interface';
import { PlatformInterface } from '@common/interfaces/api';
import { CommonService } from '@common/services/common.service';
import { ResponsiveService } from '@common/services/responsive.service';
import { AccessControlService, PermissionsForClientInterface } from '@common/services/access-control';
import { Router } from '@angular/router';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { StateService } from '@common/services/state.service';
import { ChallengesService } from '@common/services/api/client';
import { Utils } from '@common/helpers/utils';
import { BadgeNotificationsEventsInterface, NotificationsService } from '@common/services/notifications';
import { AssistanceController } from '@common/components/modals/assistance';
import { OnboardingService } from '@common/services/onboarding.service';
import { filter, map } from 'rxjs/operators';
import { params } from '@environments/params';
import { Browser } from '@maslo/browser';

import * as moment from 'moment';

/**
 * Component to manage left side profile view
 */

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {

  profile: UserProfileInterface;
  platform: PlatformInterface;

  availablePlatforms: PlatformInterface[];
  selectedPlatformId: number;

  subscriptions: Subscription[];

  isCoinsDisplay: boolean = false;
  isCashDisplayed: boolean = false;
  isGiftsDisplayed: boolean = false;
  canDisplayVideos: boolean = false;

  permissions: PermissionsForClientInterface;

  nbPlatforms: number = 10;
  pointsChallenges: number = 0;
  pointsChallengeId: number = 0;

  coins: number = 0;
  cash: number = 0;
  gifts: number = 0;
  badgeEvents?: BadgeNotificationsEventsInterface;
  hasDashboardEvents: boolean = false;

  mobileVersion?: string;
  dashboardMainRoute: string = '/';
  canAccessDashboard: boolean = false;

  currentMonth?: string;

  userHelpLink: string = params.links.user_help_link;


  platformColor = () => {
    return this.ionicPlatform.is('ios') ? 'medium' : 'green';
  };

  constructor(protected connectionServ: ConnectionService,
              protected accessServ: AccessControlService,
              protected router: Router,
              protected ionicPlatform: Platform,
              protected navCtrl: NavController,
              protected menuCtrl: MenuController,
              protected challengesServ: ChallengesService,
              protected stateServ: StateService,
              protected notificationsServ: NotificationsService,
              protected assistanceCtrl: AssistanceController,
              protected onboardingServ: OnboardingService,
              protected commonServ: CommonService,
              public responsiveServ: ResponsiveService) {
    this.subscriptions = [];
  }

  ngOnInit() {

    this.subscriptions.push(this.connectionServ.getProfileState().subscribe(profile => {
      if (profile?.user) {
        this.initData(profile?.user);
      }
    }));

    this.subscriptions.push(this.connectionServ.getPlatformState().subscribe(platform => {
      if (!!platform) {
        this.updatePlatformData();
      }
    }));

    this.subscriptions.push(this.commonServ.translocoEvents().pipe(
      filter(event => event.type === 'translationLoadSuccess'),
      map(event => event?.payload?.langName || 'en')
    ).subscribe(lang => {
      moment.locale(lang);
      this.currentMonth = moment().format('MMMM');
    }));

    this.subscriptions.push(this.stateServ.getUserCoinsState().subscribe(value => this.coins = value));
    this.subscriptions.push(this.stateServ.getUserCashState().subscribe(value => this.cash = value));
    this.subscriptions.push(this.stateServ.getUserGiftsState().subscribe(value => this.gifts = value));

    this.connectionServ.getPlatforms().subscribe(platforms => {
      this.availablePlatforms = platforms;
    });

    if (this.ionicPlatform.is('capacitor')) {
      this.mobileVersion = this.connectionServ.getCurrentVersion();
    }

    // Badge
    this.subscriptions.push(this.notificationsServ.eventsState().subscribe(events => this.badgeEvents = events));
    this.subscriptions.push(this.notificationsServ.eventsState(true).subscribe(events => this.hasDashboardEvents = events.accessFlag));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  initData(user: UserProfileInterface) {
    this.profile = user;
    this.updatePlatformData();

    // Snippet for Laforet
    if (Utils.toNumber(this.selectedPlatformId) === 477) {
      this.challengesServ.pointsChallenge().subscribe(value => {
        const challenges = value.challenges;
        if (challenges && challenges.length > 0) {
          this.pointsChallenges = Utils.toNumber(challenges[0].points_wins);
          this.pointsChallengeId = challenges[0].id;
        }
      });
    }
  }

  updatePlatformData() {
    this.platform = this.connectionServ.getSelectedPlatformValue();
    this.selectedPlatformId = this.platform?.id_action;

    this.permissions = this.accessServ.permissionsForClient();
    this.canAccessDashboard = this.commonServ.canAccessDashboard();
    this.dashboardMainRoute = this.accessServ.getDashboardMainRoute();

    this.isCoinsDisplay = this.permissions.isRewardsEnabled && Utils.resultToBoolean(this.platform?.allow_display_coins);
    this.isCashDisplayed = this.permissions.isRewardsEnabled && Utils.resultToBoolean(this.platform?.allow_display_cash);
    this.isGiftsDisplayed = this.permissions.isRewardGiftsEnabled && Utils.resultToBoolean(this.platform?.allow_display_gifts);

    this.canDisplayVideos = this.onboardingServ.isOfferForOnboarding() && this.permissions.platform?.is_admin;
  }

  /**
   * callback when platform change
   */
  onPlatformChange() {
    this.menuCtrl.close()?.then(/* Nothing to do */);
  }

  /**
   * Indicate if route is enabled
   * @param path path to test
   */
  isRouteEnabled(path: string): boolean {
    return this.router.url.startsWith(path);
  }

  /**
   * Navigate to route
   * @param route routes string
   * @param direction direction string
   */
  navigateTo(route: string, direction = 'root') {
    if (direction === 'root') {
      this.navCtrl.navigateRoot(route)?.then(/* Nothing to do */);
    } else {
      this.navCtrl.navigateForward(route)?.then(/* Nothing to do */);
    }
    this.menuCtrl.close()?.then(/* Nothing to do */);
  }

  helpAction($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.platform?.is_admin) {
      Browser.open({url: this.userHelpLink}).then(/* Nothing to do */);
    } else {
      this.assistanceCtrl.show().then(/* Nothing to do */);
    }
  }

  sponsorshipAction() {
    Browser.open({url: params.links.sponsorship}).then(/* Nothing to do */);
  }

  openFunctionality(functionality: string) {
    this.onboardingServ.introVideo(functionality);
  }

  logout() {
    this.commonServ.logout();
    this.menuCtrl.close()?.then(/* Nothing to do */);
  }

}
