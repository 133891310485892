import { HttpBaseService } from '@common/services/api/http-base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConnectionService } from '@common/services/connection.service';
import { Observable } from 'rxjs';
import {
  AdminEStoreCategoriesInterface, AdminEStoreCfgBrandItemInterface, AdminEStoreOrdersOptions,
  ReqAdminEStoreBrandsInterface,
  ReqAdminEStoreCategoriesInterface, ReqAdminEStoreOrdersInterface, ReqAdminEStoreOrdersUsersInterface
} from '@common/interfaces/api/admin';
import { StandardExportInterface, StandardResponseInterface } from '@common/interfaces/api';
import { tap } from 'rxjs/operators';

@Injectable()
export class AdminEStoreService extends HttpBaseService {

  constructor(protected http: HttpClient,
              protected connectionServ: ConnectionService) {
    super(http);

  }

  /**
   * Return all categories of e-store
   */
  categories(): Observable<ReqAdminEStoreCategoriesInterface> {
    return this.stdRequest<ReqAdminEStoreCategoriesInterface>(this.http.post<ReqAdminEStoreCategoriesInterface>(`${ this.rootApi }/administration/Estore`, null)).pipe(
      tap(response => {
        // Clean category name
        response?.estore_cfg?.categories?.forEach((category, idx) => {
          response.estore_cfg.categories[idx].name = category.name.replace(/\d+. ?/g, '');
        });
      })
    );
  }

  /**
   * Used to update categories adn prices
   * @param data data to update
   */
  updateCategories(data: AdminEStoreCategoriesInterface): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('estore_cfg', JSON.stringify(data));
    return this.stdRequest<StandardResponseInterface>(this.http.post(`${ this.rootApi }/administration/Estore/update`, body));
  }

  /**
   * Get brands by search filter
   * @param search filter value
   */
  brands(search?: string): Observable<ReqAdminEStoreBrandsInterface> {
    const body = new FormData();
    if (search) {
      body.append('search', search);
    }
    return this.stdRequest<ReqAdminEStoreBrandsInterface>(this.http.post(`${ this.rootApi }/administration/Estore/brands`, body));
  }

  /**
   * Used to update hidden brands
   * @param hiddenBrands hidden brands object
   */
  updateBrands(hiddenBrands: AdminEStoreCfgBrandItemInterface[]): Observable<StandardResponseInterface> {
    const body = new FormData();
    body.append('estore_cfg', JSON.stringify({hidden_brands: hiddenBrands}));
    return this.stdRequest<StandardResponseInterface>(this.http.post(`${ this.rootApi }/administration/Estore/update`, body));
  }

  /**
   * Used to get users rank
   */
  usersRank(options?: AdminEStoreOrdersOptions): Observable<ReqAdminEStoreOrdersUsersInterface> {
    const body = new FormData();
    body.append('order_by', options?.sort || 'coins_amount');
    return this.stdRequest<ReqAdminEStoreOrdersUsersInterface>(this.http.post(`${ this.rootApi }/administration/Order`, body));
  }

  /**
   * Used to get orders list
   */
  orders(options?: AdminEStoreOrdersOptions): Observable<ReqAdminEStoreOrdersInterface> {
    const body = new FormData();
    body.append('orders_only', 'true');
    if (options?.users?.length > 0) {
      body.append('users', JSON.stringify(options.users.map(val => val.toString())));
    }
    if (options?.dateFrom) {
      body.append('date_from', options.dateFrom);
    }
    if (options?.dateTo) {
      body.append('date_to', options.dateTo);
    }
    if (options?.sort) {
      body.append('order_by', options.sort);
    }
    return this.stdRequest<ReqAdminEStoreOrdersInterface>(this.http.post(`${ this.rootApi }/administration/Order`, body));
  }

  /**
   * Used to export orders list in csv
   */
  ordersCsv(options?: AdminEStoreOrdersOptions): Observable<StandardExportInterface> {
    const body = new FormData();
    body.append('orders_only', 'true');
    body.append('get_csv', '1');
    if (options?.urssaf) {
      body.append('urssaf', '1');
    }
    if (options?.users?.length > 0) {
      body.append('users', JSON.stringify(options.users));
    }
    if (options?.dateFrom) {
      body.append('date_from', options.dateFrom);
    }
    if (options?.dateTo) {
      body.append('date_to', options.dateTo);
    }
    if (options?.sort) {
      body.append('order_by', options.sort);
    }
    return this.stdRequest<StandardExportInterface>(this.http.post(`${ this.rootApi }/administration/Order`, body));
  }

}
