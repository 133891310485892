import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnChanges, ControlValueAccessor {

  @Input() value: string = '';
  @Input() placeholder: string = '';
  @Input() color: string = 'dark';
  @Input() iconColor: string = 'primary';
  @Input() disableKeyEnterTrigger: boolean = false;
  @Input() triggerOnKeyUp: boolean = false;
  @Input() icon?: string;

  @Input() size: 'x-small' | 'normal' = 'normal';

  @Input() disabled: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  isFocused: boolean = false;

  public onChange = (_: any) => {
  };
  public onTouch = () => {
  };

  constructor(private rdr: Renderer2,
              private elt: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.size && this.size) {
      this.buildCss();
    }

  }

  buildCss() {
    this.rdr.removeClass(this.elt.nativeElement, 'size-normal');
    this.rdr.removeClass(this.elt.nativeElement, 'size-x-small');
    this.rdr.addClass(this.elt.nativeElement, 'size-' + this.size);
  }

  updateValue(value?: string) {
    this.onChange(value || this.value);
    this.valueChange.emit(value || this.value);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.disableKeyEnterTrigger) {
      this.updateValue();
    }
  }

  onKeyUp(event: KeyboardEvent) {
    if (this.triggerOnKeyUp) {
      this.updateValue();
    }
  }

  /**
   * ControlValueAccessor methods
   */
  /** It writes the value in the input */
  public async writeValue(inputValue: any): Promise<void> {

    this.value = inputValue;

    return;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
